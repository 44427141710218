import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import ImgCard from "../../Components/ImgCard";
import Header from "../../Components/header";
import { Card, Pagination, Typography } from "antd";
import Title from "antd/es/typography/Title";
import {Helmet} from "react-helmet";
import { newsByCategory } from "../../Redux/newsByCategory/newsByCategoryThunk";
import { rightSideData, rightSideFour, rightSideSecound, rightSideThird } from "../../Redux/leftSideData/leftSideDataThunk";
import Footer from "../../Components/Footer";
import { api, apiEndPoints } from "../../api";
import { getAuthorNews } from "../../Redux/Author/categotyThunk";
export default function AuthorPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const path = useParams();
    const [page,setPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);
    const centerData = useSelector(store=>store?.newsByAuthor?.authorNews)
    const [news,setNews] = useState();
    const rightSide = useSelector(store=>store?.leftSideData?.rightSide)
    const rightSideSecoundData = useSelector(store=>store?.leftSideData?.rightSideSecound)
    const rightSideThirdData = useSelector(store=>store?.leftSideData?.rightSideThird)
    const rightSideFourth = useSelector(store=>store?.leftSideData?.rightSideFour)
    const { Text } = Typography;
    const dispatch = useDispatch()

    useEffect(()=>{
      if(path?.name){
        dispatch(getAuthorNews({page:page,limit:20, name:path?.name})) 
        dispatch(rightSideData({page:"1",limit:"1",type:"World"}))
        dispatch(rightSideSecound({page:"1",limit:"1",type:"Sports"}))
        dispatch(rightSideThird({page:"1",limit:"1",type:"Lifestyle"}))
        !(rightSideFourth?.length>=2)&&dispatch(rightSideFour({page:"1",limit:"1",type:"Entertainment"}))
        !(rightSideFourth?.length>=2)&&dispatch(rightSideFour({page:"1",limit:"1",type:"Tech"}))

      }
    },[])
    useEffect(()=>{
      if(centerData?.length){
        setNews(centerData[0])
      }
    },[centerData])
    
    const viewAllHandle = async(type) => {
      if(type==='Home'){
        await navigate("/")
      }else{
        await navigate(`/${type}`)
        await dispatch(newsByCategory({page:page,limit:"20",type:type}))
      }
      window.scrollTo(0,0)
    }
    
    const onChangePagination = (page,pageSize) => {
      setPage(page)
      setPageSize(pageSize)
      dispatch(getAuthorNews({page:page,limit:20, name:path?.name}))
      window.scrollTo(0,0)
    }

  return (
  <>
    <Header viewAllHandle={viewAllHandle}/>
    <Helmet>
      {/*<title>here ypu can title </title>*/}
      <meta name='description' content='this is nothing'/>
      <meta name='keywords' content='news,games'/>
    </Helmet>
    <div>
      <div className="main-part">
        {/* <div className="side-bar-of-category">
          <div className="left-cards">
            <div class="leftside-first">
              <h5>Game News</h5>
            </div>
            {leftFirstData?.data?.length?leftFirstData?.data?.map((i,index)=>{
              return (index<4&&
              <SmallCard item={i}/>)
            }):""}
            <div className="view-all" onClick={()=>{viewAllHandle("Game")}}>
              <h5>{"View All >"}</h5>
            </div>
          </div>
          <div className="left-cards sticky" >
          <div class="leftside-first">
            <h5>India</h5>
          </div>
          {leftSecoundData?.data?.length?leftSecoundData?.data?.map((i,index)=>{
            return (index<4&&
            <SmallCard item={i}/>)
          }):""}
          <div className="view-all" onClick={()=>{viewAllHandle("India")}}>
            <h5>{"View All >"}</h5>
          </div>
          </div>
        </div> */}
        <div className="center-main">
          <div className="center">
            <div className="w-11/12 ml-2.5 pt-2 text-left"><Title onClick={()=>{navigate("/")}} className="cursor-pointer inline-block" level={5}>Home / </Title><Text> Author / </Text><Text> {path?.name}</Text></div>
            <Card style={{width: "95%",margin:"0 auto"}} className="text-left"><div className="AuthorNameTag">{path?.name?path?.name[0].toUpperCase():""}</div>{path?.name}</Card>
            {news?.data?.length?news?.data?.map((i,index)=>{
              return (<ImgCard viewAllHandle={viewAllHandle} item={i}/>)
            }):""}
            <Pagination className="pagination" current={page} total={news?.count?news?.count[0]:"10"} pageSize={pageSize} onChange={onChangePagination}/>
          </div>
        </div>
        <div className="side-bar-of-news">
          <div className="left-cards sticky">
            <div>
            <div class="leftside-first">
              <h5>Top News</h5>
            </div>
            {rightSide?.data?.length?rightSide?.data?.map((i,index)=>{
              return (index<4&&
                <ImgCard leftSide={true} item={i}/>)
              }):""}
              </div>
          {rightSideSecoundData?.data?.length?
          <div >
            {rightSideSecoundData?.data?.length?rightSideSecoundData?.data?.map((i,index)=>{
              return (index<4&&
              <ImgCard leftSide={true} item={i}/>)
            }):""}
          </div>:""}
          {rightSideThirdData?.data?.length?<div >
            {rightSideThirdData?.data?.length?rightSideThirdData?.data?.map((i,index)=>{
              return (index<4&&
              <ImgCard leftSide={true} item={i}/>)
            }):""}
          </div>:""}
          {rightSideFourth?.length?<div >
            {rightSideFourth?.length?rightSideFourth?.map((i,index)=>{
              return (index<4&&
              <ImgCard leftSide={true} item={i}/>)
            }):""}
          </div>:""}
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  );
}
