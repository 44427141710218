import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AdminNav from "../../Components/AdminCategory";
import {Button, Form, Input, Popconfirm, Select, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {deleteUser, getAllAdmin, postAdmin} from "../../Redux/admins/adminThunk";
import "./index.css";

export default function AdminUsers() {
    const navigate = useNavigate();
    const [users, setUsers] = useState();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const [addUser, setAddUser] = useState(false)
    const role = localStorage.getItem('role');
    useEffect(() => {
        if (!role) {
            navigate("/")
        } else {
            if (role === "admin") {
                dispatch(getAllAdmin())
            } else {
                navigate("/admin-panel")
            }
        }
    }, [])

    useEffect(() => {
        if (store?.users?.users) {
            const data = Array.isArray(store?.users?.users) && store?.users?.users?.map((item, index) => {
                return {key: index + 1, ...item};
            });
            setUsers(data)
        }
    }, [store?.users?.users])

    const columns = [
        {
            title: "No",
            dataIndex: "key",
            sorter: {
                compare: (a, b) => a.key - b.key,
                multiple: 3,
            },
        },
        {
            title: "Username",
            dataIndex: "email",
            align: "left",
        },
        {
            title: "Role",
            dataIndex: "role",
            align: "center",
        },
        {
            title: "Action",
            align: "center",
            render: function (text, record, index) {
                return (
                    <Popconfirm
                        title="Delete the user"
                        description="Are you sure to delete this user or admin?"
                        onConfirm={() => handleDelete(text?._id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div
                            class="text-center cursor-pointer font-medium text-red-500"
                        >
                            Delete
                        </div>
                    </Popconfirm>
                );
            },
        },
    ];

    const onFinish = async (values) => {
        await dispatch(postAdmin(values))
        await dispatch(getAllAdmin())
        setAddUser(false)
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleChange = (value) => {
    };

    const handleDelete = async (id) => {
        await dispatch(deleteUser({id: id}));
        await dispatch(getAllAdmin());
    };

    return (
        <div class="w-full dark:bg-gray-900" style={{minHeight: "100vh"}}>
            <AdminNav/>
            <div className="dark:bg-gray-900">

                {addUser ?
                    <div
                        className="min-h-[93.4vh] relative overflow-hidden dark:bg-gray-900 flex justify-center items-center">
                        <div
                            className="absolute w-60 h-60 rounded-xl dark:bg-gray-300 -top-5 -left-16 z-0 transform rotate-45 hidden md:block">
                        </div>
                        <div
                            className="absolute w-48 h-48 rounded-xl dark:bg-gray-300 -bottom-6 -right-10 transform rotate-12 hidden md:block">
                        </div>
                        <div className="py-10 px-12 dark:bg-gray-300 rounded-2xl shadow-xl z-20">
                            <div>
                                <h1 className="text-3xl font-bold text-center mb-10 cursor-pointer">Add User /
                                    Admin</h1>
                                <Form
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 16,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="Author"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input valid user username!",
                                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input valid user password!",
                                                pattern: /(?=.*\d)(?=.*[a-z])(?!.*\s)(?=.*[!@#$*])/,
                                            },
                                        ]}
                                    >
                                        <Input.Password/>

                                    </Form.Item>
                                    <Form.Item
                                        label="Role"
                                        name="role"
                                        style={{textAlign: "left"}}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Select admin role!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange={handleChange}
                                            options={[
                                                {
                                                    value: 'admin',
                                                    label: 'Admin',
                                                },
                                                {
                                                    value: 'user',
                                                    label: 'User',
                                                }
                                            ]}
                                        />
                                    </Form.Item>

                                    <Form.Item className="buttons_of_adduser">
                                        <Button
                                            type="primary"
                                            style={{backgroundColor: "#1f2937", color: "#fff"}}
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            type="primary"
                                            style={{backgroundColor: "#1f2937", color: "#fff", marginLeft: "23px"}}
                                            onClick={() => setAddUser(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                        <div
                            className="w-40 h-40 absolute dark:bg-gray-300 rounded-full top-0 right-12 hidden md:block"></div>
                        <div
                            className="w-20 h-40 absolute dark:bg-gray-300 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block">
                        </div>
                    </div>
                    :
                    <div className='relative overflow-hidden dark:bg-gray-900' style={{marginTop: "25px"}}>
                        <div
                            className="absolute w-60 h-60 rounded-xl dark:bg-gray-300 -top-[-50px] -left-16 z-0 transform rotate-45 hidden md:block">
                        </div>
                        <div
                            className="absolute w-48 h-48 rounded-xl dark:bg-gray-300 -bottom-6 -right-10 transform rotate-12 hidden md:block">
                        </div>
                        <Button type="primary" className="m-4" style={{backgroundColor: "#1f2937", color: "#fff"}}
                                onClick={() => setAddUser(true)} size={"large"}>
                            Add Admin
                        </Button>
                        <section class="antialiased text-gray-600 px-4">
                            <div class="flex flex-col justify-center">
                                <div
                                    class="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-sm border border-gray-200 rounded-xl">
                                    <header class="px-5 py-4 border-b border-gray-100">
                                        <h2 class="font-semibold text-gray-800">Users</h2>
                                    </header>
                                    <div class="p-3">
                                        <div class="overflow-x-auto">
                                            <Table columns={columns} dataSource={users}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div
                            className="w-40 h-40 absolute dark:bg-gray-300 rounded-full top-0 right-12 hidden md:block"></div>
                        <div
                            className="w-20 h-40 absolute dark:bg-gray-300 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block">
                        </div>
                    </div>}
            </div>
        </div>
    );
}
