import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.css";
import Logo from '../../assets/intalkss_logo.png'
import Title from "antd/es/typography/Title";
import { FooterCategory, Footerdetails } from "./constant";
import Typography from "antd/es/typography/Typography";
import { newsByCategory } from "../../Redux/newsByCategory/newsByCategoryThunk";

export default function Footer () {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onCategoryClick = async(category) => {
        await navigate(`/${category}`)
        await dispatch(newsByCategory({page:"1",limit:20,type:category}))
        await window.scrollTo(0,0)
    }

    return(
     <footer className="footer-main">
        <div className="footer-inner">
            <div className="footer-component" style={{display:"flex",justifyContent:"space-between",paddingRight:"15px"}}>
                <div className="footer-third-part">
                    <div class="flex items-center" onClick={()=>{navigate("/")}}>
                        <img src={Logo} alt="" width='100px'/>
                    </div>
                    <Title level={4} className="footer-header" style={{padding:"10px 0px"}}>What You Read Is What You Get</Title>
                </div>
            </div>
            <div className="footer-middle-component footer-forth">
                <div className="category-first">
                {FooterCategory.map((i)=>(
                    <div>
                        <Typography style={{paddingBottom:"10px",color:"#aab2bd",cursor:"pointer", fontSize:"17px",fontWeight:"bolder"}} onClick={()=>{onCategoryClick(i.category)}}>
                            {i.category}
                        </Typography>
                        {i.subCat?i.subCat.map((subcat)=>(
                            <Typography style={{color:"#aab2bdcc",cursor:"pointer", fontSize:"14px",paddingBottom:"10px"}} onClick={()=>{navigate(`/${i.category}/${subcat}/sub`)}}>
                                {subcat}
                            </Typography>
                        )):""}
                    </div>
                ))}
                </div>
                <div className="category-first">
                    {Footerdetails.map((i)=>(
                        <div>
                            <Typography style={{paddingBottom:"10px",color:"#aab2bd",cursor:"pointer", fontSize:"17px",fontWeight:"bolder"}} onClick={()=>{onCategoryClick(i.category)}}>
                                {i.category}
                            </Typography>
                            {i.subCat?i.subCat.map((subcat)=>(
                                <Typography style={{color:"#aab2bdcc",cursor:"pointer", fontSize:"14px",paddingBottom:"10px"}} onClick={()=>{navigate(`/${i.category}/${subcat}/sub`)}}>
                                    {subcat}
                                </Typography>
                            )):""}
                        </div>
                    ))}
                </div>
            </div>
            <div className="footer-component" style={{textAlign:"left",paddingLeft:"20px"}}>
                <Typography  className="footer-details" onClick={()=>navigate("/about-us")}>About Us</Typography>
                <Typography className="footer-details" onClick={()=>navigate("/privacy")}>Privacy Policy</Typography>
                <Typography className="footer-details" onClick={()=>navigate("/terms")}>Terms Of Use</Typography>
                <Typography className="footer-details" >intalkss24@gmail.com</Typography>
            </div>
            <div className="text-center mt-30 w-100">   
                <p>@2023 intalkss. All rights reserved. </p>
            </div>
        </div>
     </footer>   
    )
}