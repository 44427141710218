import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { api, apiEndPoints } from "../../api";

export const loginUser = createAsyncThunk('loginUser', async (payload) => {
  try {
    const data = await api.post(apiEndPoints.login(),payload?.data)
    if(data.status){
      toast.success('Login Sucessfull');
      localStorage.setItem('token',data?.data?.token)
      localStorage.setItem('role',data?.data?.user?.role)
      localStorage.setItem('name',data?.data?.user?.name || 'admin')
      payload?.navigate('/admin-panel',{state:{LoggedIn:true}})
      return data 
    }else{
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Login Fail", {
        position: "top-right",
        autoClose: 5000,
        theme: "dark",
      });
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})