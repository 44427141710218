import AdminNav from "../../Components/AdminCategory";
import {useEffect, useState} from "react";
import api from "../../api/api";
import {apiEndPoints} from "../../api";
import "../../Components/ImgCard/index.css";
import Paragraph from "antd/es/typography/Paragraph";
import {Link} from "react-router-dom";
import {Button, Pagination} from "antd";
import {toast} from "react-toastify";

export default function AdminMyNews(){
    const [news,setNews] = useState();
    const [page,setPage] = useState(1);
    const [pageSize,setPageSize] = useState(15);
    useEffect(()=> {
        handleGetNews();
    },[])

    const handleGetNews = async (page) => {
        const name = localStorage.getItem('name') || "admin";
        const data = await api.get(apiEndPoints.getMyNews( page || 1, 15,  name));
        const news = data?.data.length ? data?.data[0] : []
        setNews(news)
    }

    const onChangePagination = (page,sizePage) => {
        setPage(page)
        setPageSize(sizePage);
        handleGetNews(page);
        window.scrollTo(0,0)
    }

    const deleteNews = async (id) => {
        const data = await api.delete(apiEndPoints.deleteNews(id));
        if(data?.data?.massage === 'news deleted successfully'){
            toast.error(data?.data?.massage);
        }
        handleGetNews();
    }

    return(
        <div className="w-full bg-gray-100" style={{minHeight: "100vh"}}>
            <AdminNav />
            <div
                className="min-h-[93.4vh] relative overflow-hidden dark:bg-gray-900 flex justify-center items-center">
                <div
                    className="absolute w-60 h-60 rounded-xl dark:bg-gray-300 -top-5 -left-16 z-0 transform rotate-45 hidden md:block">
                </div>
                <div
                    className="absolute w-48 h-48 rounded-xl dark:bg-gray-300 -bottom-6 -right-10 transform rotate-12 hidden md:block">
                </div>
                <div className='mt-5 z-10 set-image'>
                    {news?.data?.length ? news.data.map((item)=>{
                        return <div className='mb-5 bg-white rounded text-start'><div className="small-main" style={{width:"100%"}}>
                            <div className={"small-details center-img center-padding"}>
                                    <div className='flex flex-col justify-center'>
                                        <div><Link className="last-update text-sm text-blue-500">{item.category}</Link><Button onClick={() => deleteNews(item?._id)} className='text-sm last-update ml-1' type="text" danger>delete</Button></div>
                                        <Paragraph level={5} style={{width:"100%", margin: '0 0 5px 0'}}
                                                   ellipsis={{
                                                       rows: 2,
                                                       expandable: false,
                                                   }} className="desc-link">{item.title}</Paragraph>
                                        <Paragraph level={5} style={{width:"100%",margin: 0}}
                                                   ellipsis={{
                                                       rows: 2,
                                                       expandable: false,
                                                   }} className='desc-link-2'>{item.smallDescription}</Paragraph>
                                    </div>
                            </div>
                            <div className={"small-image"}>
                                <img src={item.imageUrl} alt="" style={{minWidth:"100%",minHeight:"100%"}}/>
                            </div>
                        </div></div>
                        })
                        : null}
                    <div className='bg-white'>
                        <Pagination className="pagination" current={page} total={news?.count?news?.count[0]:"10"} pageSize={pageSize} onChange={onChangePagination}/>
                    </div>
                </div>
                <div
                    className="w-40 h-40 absolute dark:bg-gray-300 rounded-full top-0 right-12 hidden md:block"></div>
                <div
                    className="w-20 h-40 absolute dark:bg-gray-300 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block">
                </div>
            </div>
        </div>
    )
}