import "./index.css";
import Header from "../../Components/header";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ImgCard from "../../Components/ImgCard";
import "../HomePage/index.css";

import Footer from "../../Components/Footer";
import {useDispatch, useSelector} from "react-redux";
import {
    rightSideData, rightSideFour,
    rightSideSecound, rightSideThird
} from "../../Redux/leftSideData/leftSideDataThunk";

export default function PrivacyPolicy() {
    const dispatch = useDispatch()
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const navigate = useNavigate();
    const searchdata = useSelector(store => store)
    const rightSide = useSelector(store => store?.leftSideData?.rightSide)
    const rightSideSecoundData = useSelector(store => store?.leftSideData?.rightSideSecound)
    const rightSideThirdData = useSelector(store => store?.leftSideData?.rightSideThird)
    const isSearch = searchdata?.newsByCategory?.newsBySearch && searchdata?.newsByCategory?.newsBySearch[0]?.data
    const rightSideFourth = useSelector(store => store?.leftSideData?.rightSideFour)

    useEffect(() => {
        dispatch(rightSideData({page: "1", limit: "1", type: "World"}))
        dispatch(rightSideSecound({page: "1", limit: "1", type: "Sports"}))
        dispatch(rightSideThird({page: "1", limit: "1", type: "Lifestyle"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Entertainment"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Tech"}))
        window.scrollTo(0, 0)
    }, [])

    const viewAllHandle = (type) => {
        if (type === 'Home') {
            navigate("/")
        } else {
            navigate(`/${type}`)
        }
        window.scrollTo(0, 0)
    }
    return (
        <>
            <Header viewAllHandle={viewAllHandle} searched={location?.state?.search} page={page}
                    setSearchPagination={setSearch}/>
            <div>
                <div className="main-part">
                    <div className="center-main">
                        <div className="center">
                            <div className="center-heading">
                                <h5>Privacy Policy</h5>
                            </div>
                            <div className="container mx-auto py-8 text-left">

                                <p>Last updated: June 19, 2023</p>

                                <p>
                                    Intalkss (“us”, “we”, or “our”) operates the Intalkss website (the
                                    “Service”).
                                </p>

                                <p>
                                    This page informs you of our policies regarding the collection, use and
                                    disclosure of Personal Information when you use our Service.
                                </p>

                                <p>
                                    We will not use or share your information with anyone except as
                                    described in this Privacy Policy.
                                </p>

                                <p>
                                    We use your Personal Information for providing and improving the
                                    Service. By using the Service, you agree to the collection and use of
                                    information in accordance with this policy. Unless otherwise defined in
                                    this Privacy Policy, terms used in this Privacy Policy have the same
                                    meanings as in our Terms and Conditions, accessible at{' '}
                                    <a
                                        href="https://www.intalkss.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500"
                                    >
                                        https://www.intalkss.com
                                    </a>
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">
                                    Information Collection And Use
                                </h2>
                                <hr className="mb-4"/>

                                <p>
                                    While using our Service, we may ask you to provide us with certain
                                    personally identifiable information that can be used to contact or
                                    identify you. Personally identifiable information (“Personal
                                    Information”) may include, but is not limited to: Email address
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Third party advertising</h2>
                                <hr className="mb-4"/>

                                <p>
                                    We carefully select third-party advertisers based on their reputation,
                                    credibility, and alignment with our editorial standards. We strive to
                                    work with advertisers whose products or services are relevant,
                                    non-offensive, and of interest to our audience.
                                </p>

                                <p>
                                    All third-party advertisements on our website will be clearly labeled
                                    as "Advertisement" or "Sponsored Content." We differentiate between
                                    editorial content and advertising to ensure transparency for our
                                    readers.
                                </p>

                                <p>
                                    Advertisements displayed on our website do not influence our editorial
                                    content. We maintain strict separation between advertising and
                                    editorial functions, and our editorial team has full control over the
                                    content and topics covered in our articles. We review and approve all
                                    third-party ads before they are displayed on our website.
                                    Advertisements must comply with our advertising guidelines, which
                                    prohibit content that is misleading, deceptive, offensive, illegal, or
                                    violates our editorial standards.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Log Data</h2>
                                <hr className="mb-4"/>

                                <p>
                                    We collect information that your browser sends whenever you visit our
                                    Service (“Log Data”). This Log Data may include information such as your
                                    computer’s Internet Protocol (“IP”) address, browser type, browser
                                    version, the pages of our Service that you visit, the time and date of
                                    your visit, the time spent on those pages and other statistics.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">
                                    Google AdSense & DoubleClick Cookie
                                </h2>
                                <hr className="mb-4"/>

                                <p>
                                    Google, as a third party vendor, uses cookies to serve ads on our
                                    Service.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Cookies</h2>
                                <hr className="mb-4"/>

                                <p>
                                    Cookies are files with small amount of data, which may include an
                                    anonymous unique identifier. Cookies are sent to your browser from a web
                                    site and stored on your computer’s hard drive.
                                </p>

                                <p>
                                    We use “cookies” to collect information. You can instruct your browser
                                    to refuse all cookies or to indicate when a cookie is being sent.
                                    However, if you do not accept cookies, you may not be able to use some
                                    portions of our Service.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Service Providers</h2>
                                <hr className="mb-4"/>

                                <p>
                                    We may employ third party companies and individuals to facilitate our
                                    Service, to provide the Service on our behalf, to perform
                                    Service-related services or to assist us in analyzing how our Service is
                                    used.
                                </p>

                                <p>
                                    These third parties have access to your Personal Information only to
                                    perform these tasks on our behalf and are obligated not to disclose or
                                    use it for any other purpose.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Security</h2>
                                <hr className="mb-4"/>

                                <p>
                                    The security of your Personal Information is important to us, but
                                    remember that no method of transmission over the Internet, or method of
                                    electronic storage is 100% secure. While we strive to use commercially
                                    acceptable means to protect your Personal Information, we cannot
                                    guarantee its absolute security.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Children’s Privacy</h2>
                                <hr className="mb-4"/>

                                <p>
                                    Our Service does not address anyone under the age of 18 (“Children”).
                                </p>

                                <p>
                                    We do not knowingly collect personally identifiable information from
                                    children under 18. If you are a parent or guardian and you are aware
                                    that your child has provided us with Personal Information, please
                                    contact us. If we discover that a child under 18 has provided us with
                                    Personal Information, we will delete such information from our servers
                                    immediately.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Compliance With Laws</h2>
                                <hr className="mb-4"/>

                                <p>
                                    We will disclose your Personal Information where required to do so by
                                    law or subpoena.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Links To Other Sites</h2>
                                <hr className="mb-4"/>

                                <p>
                                    Our Service may contain links to other sites that are not operated by
                                    us. If you click on a third party link, you will be directed to that
                                    third party’s site. We strongly advise you to review the Privacy Policy
                                    of every site you visit.
                                </p>

                                <p>
                                    We have no control over, and assume no responsibility for the content,
                                    privacy policies or practices of any third party sites or services.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">
                                    Changes To This Privacy Policy
                                </h2>
                                <hr className="mb-4"/>

                                <p>
                                    We may update our Privacy Policy from time to time. We will notify you
                                    of any changes by posting the new Privacy Policy on this page.
                                </p>

                                <p>
                                    You are advised to review this Privacy Policy periodically for any
                                    changes. Changes to this Privacy Policy are effective when they are
                                    posted on this page.
                                </p>

                                <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
                                <hr className="mb-4"/>

                                <p>
                                    If you have any questions about these Terms and Conditions, You can
                                    contact us:
                                </p>

                                <ul>
                                    <li>By email: intalkss24@gmail.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="side-bar-of-news">
                        <div className="left-cards sticky">
                            <div>
                                <div className="leftside-first">
                                    <h5>Top News</h5>
                                </div>
                                {rightSide?.data?.length ? rightSide?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div>
                            {rightSideSecoundData?.data?.length ?
                                <div>
                                    {rightSideSecoundData?.data?.length ? rightSideSecoundData?.data?.map((i, index) => {
                                        return (index < 4 &&
                                            <ImgCard leftSide={true} item={i}/>)
                                    }) : ""}
                                </div> : ""}
                            {rightSideThirdData?.data?.length ? <div>
                                {rightSideThirdData?.data?.length ? rightSideThirdData?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                            {rightSideFourth?.length ? <div>
                                {rightSideFourth?.length ? rightSideFourth?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
