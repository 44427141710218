import { Card, Skeleton, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

export default function MainCard({item}) {
    const navigate = useNavigate();
    const { Text, Link } = Typography;
    const timeCalculate = (createdDate) => {
        // const nowTime = new Date()
        // const hours = Math.floor((nowTime.getTime() - time.getTime()) / 1000 / 60 / 60);
        // const mins = Math.floor((nowTime.getTime() - time.getTime()) / 1000 / 60 );
        // const sec = Math.floor((nowTime.getTime() - time.getTime()) / 1000 );
        // if(hours){
        //     return `${hours} Hours` 
        // }else if(mins){
        //     return `${mins} Mins`
        // }else if(sec){
        //     return `${sec} Sec`
        // }
          // Convert the input string to a Date object
        const createdDateTime = new Date(createdDate);

        // Calculate the elapsed time in milliseconds
        const elapsedTime = Date.now() - createdDateTime.getTime();

        // Check if the elapsed time is 24 hours or more
        if (elapsedTime >= 24 * 60 * 60 * 1000) {
            // Format and return the date
            return createdDateTime.toISOString().slice(0, 10);
        } else {
            // Convert and return the elapsed time in minutes or hours
            const hours = Math.floor(elapsedTime / (60 * 60 * 1000));
            return hours > 0 ? `${hours} hours ago` : `${Math.floor(elapsedTime / (60 * 1000))} minutes ago`;
        }
    }
    function toSentenceCase(str){
        return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);;
      }
  return (
    <Card
        style={{
        width: "95%",
        marginTop: 16,
        margin:"10px auto"
        }}
        className="center-card"
        onClick={()=>{navigate(`/${item?.category}/${item?._id}`)}}
    >
        <Skeleton loading={!item}>
            <Title level={4} className="desc-link">{item?.title?toSentenceCase(item?.title):""}</Title>
            <Link>{item?.subCat || item?.category}</Link><Text type="secondary"> · Published {timeCalculate(item?.createdAt)}</Text>
            <div className="img-cover">
                <img src={item?.imageUrl} alt="" style={{minWidth:"100%"}}/>
            </div>
        </Skeleton>
    </Card>
  )
}
