import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminNav() {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const [toggle, setToggle] = useState(true);
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  useEffect(()=>{
    if(!token||!role){
      navigate("/")
    }
  },[])
  return !token ? (
    <div class="flex items-center justify-center" style={{height:"100vh"}}>
      <div
        class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  ) : (
    <div class="w-full">
      <nav class="bg-white shadow-lg">
        <div class="md:flex items-center justify-between py-2 px-8 md:px-12">
          <div class="flex justify-between items-center">
            <div class="text-2xl font-bold text-gray-800 md:text-3xl">
              <a onClick={()=>{navigate("/admin-panel")}}>Intalkss</a>
            </div>
            <div class="md:hidden" on onClick={() => setToggle(!toggle)}>
              <button
                type="button"
                class="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
              >
                <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path
                    class="hidden"
                    d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
                  />
                  <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                </svg>
              </button>
            </div>
          </div>
          <div
            class={`flex flex-col md:flex-row ${
              toggle && "hidden"
            } md:block -mx-2`}
          >
            {role==="admin"?<a
              onClick={() => {
                navigate("/admin-user");
              }}
              style={path=="/admin-user"?{textDecoration:"none",backgroundColor:"#1f2937",color:"#fff"}:{textDecoration:"none"}}
              class="text-gray-800 rounded hover:bg-gray-900 hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 cursor-pointer"
            >
              Author
            </a>:""}
            {role==="admin"?<a
              onClick={() => {
                navigate("/admin-category");
              }}
              style={path=="/admin-category"?{textDecoration:"none",backgroundColor:"#1f2937",color:"#fff"}:{textDecoration:"none"}}
              class="text-gray-800 rounded hover:bg-gray-900 hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 cursor-pointer"
            >
              Categories
            </a>:""}
            <a
                onClick={() => {
                  navigate("/admin-mynews");
                }}
                style={path == "/admin-mynews" ? {
                  textDecoration: "none",
                  backgroundColor: "#1f2937",
                  color: "#fff"
                } : {textDecoration: "none"}}
                className="text-gray-800 rounded hover:bg-gray-900 hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 cursor-pointer"
            >
              My News
            </a>
            <a
              onClick={() => {
                navigate("/admin-news");
              }}
              style={path=="/admin-news"?{textDecoration:"none",backgroundColor:"#1f2937",color:"#fff"}:{textDecoration:"none"}}
              class="text-gray-800 rounded hover:bg-gray-900 hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 cursor-pointer"
            >
              News
            </a>
            <a
              onClick={() => {
                localStorage.clear();
                navigate("/")
              }}
              style={{textDecoration:"none"}}
              class="text-gray-800 rounded hover:bg-[#1f2937] hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 cursor-pointer"
            >
              Logout
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
