import { createSlice } from '@reduxjs/toolkit'
import { loginUser } from './userThunk'

const initialState = {
  value: 0,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(loginUser.fulfilled, (state, action) => {
      // Add user to the state array
      state.value=action.payload
    })
  },

})

export default userSlice.reducer