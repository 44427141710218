import { Card, Skeleton, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";


export default function ImgCard({viewAllHandle,item,leftSide}) {
    const navigate = useNavigate();
    const { Text, Link } = Typography;
    const [rows, setRows] = useState(2);
    function toSentenceCase(str){
        return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);;
      }
  return (
    <Card
        style={{
        width: "95%",
        marginTop: 16,
        margin:"10px auto",
        }}
        className={leftSide?"center-img-card img-card-padding":"center-img-card"}
        onClick={()=>{navigate(`/${item?.category}/${item?._id}`)}}
    >
        <Skeleton loading={!item} >
            <div className="small-main" style={leftSide?{height:"75px"}:{width:"100%"}}>
                <div className={leftSide?"small-details":"small-details center-img center-padding"}>
                {leftSide?<Paragraph
                 className="desc-link"
                level={5} style={{width:"100%", lineHeight: 1.2, margin: 0}}
                    ellipsis={{
                        rows:3,
                        expandable: false,
                    }}
                    >
                    {item?.title?toSentenceCase(item?.title):""}
                </Paragraph>:
                    <div className='flex flex-col justify-center'>
                    <Link className="last-update" onClick={()=>{viewAllHandle(item.category)}}>{item?.subCat || item.category} </Link>
                    <Paragraph level={5} style={{width:"100%", margin: '0 0 5px 0'}}
                               ellipsis={{
                                   rows,
                                   expandable: false,
                               }} className="desc-link">{item?.title?toSentenceCase(item?.title):""}</Paragraph>
                        <Paragraph level={5} style={{width:"100%",margin: 0}}
                                   ellipsis={{
                                       rows,
                                       expandable: false,
                                   }} className='desc-link-2'>{item.smallDescription}</Paragraph>
                    </div>}
                </div>
                <div className={leftSide?"small-image-side":"small-image"}>
                    <img src={item.imageUrl} alt="" style={{minWidth:"100%",minHeight:"100%"}}/>
                </div>
            </div>
        </Skeleton>
    </Card>
  )
}
