export const Categories = [
  { id: 1, name: "Alice" },
  { id: 2, name: "Bob" },
  { id: 3, name: "Charlie" },
  { id: 4, name: "David" },
  { id: 5, name: "Emily" },
  { id: 6, name: "Frank" },
  { id: 7, name: "Grace" },
  { id: 8, name: "Henry" },
  { id: 9, name: "Isabella" },
  { id: 10, name: "Jack" },
];

export const data = [
  {
    key: 1,
    name: "John Brown",
    chinese: 98,
    math: 60,
    english: 70,
  },
  {
    key:2,
    name: "Jim Green",
    chinese: 98,
    math: 66,
    english: 89,
  },
  {
    key: 3,
    name: "Joe Black",
    chinese: 98,
    math: 90,
    english: 70,
  },
  {
    key: 4,
    name: "Jim Red",
    chinese: 88,
    math: 99,
    english: 89,
  },
  {
    key:5,
    name: "Jim Green",
    chinese: 98,
    math: 66,
    english: 89,
  },
  {
    key: 6,
    name: "Joe Black",
    chinese: 98,
    math: 90,
    english: 70,
  },
  {
    key: 7,
    name: "Jim Red",
    chinese: 88,
    math: 99,
    english: 89,
  },
  {
    key: 8,
    name: "Jim Green",
    chinese: 98,
    math: 66,
    english: 89,
  },
  {
    key: 9,
    name: "Joe Black",
    chinese: 98,
    math: 90,
    english: 70,
  },
  {
    key: 10,
    name: "Jim Red",
    chinese: 88,
    math: 99,
    english: 89,
  },
  {
    key: 11,
    name: "Jim Green",
    chinese: 98,
    math: 66,
    english: 89,
  },
  {
    key: 12,
    name: "Joe Black",
    chinese: 98,
    math: 90,
    english: 70,
  },
  {
    key: 13,
    name: "Jim Red",
    chinese: 88,
    math: 99,
    english: 89,
  },
];

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]