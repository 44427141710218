import {useEffect, useState} from "react";
import MainCard from "../../Components/MainCard";
import ImgCard from "../../Components/ImgCard";
import Header from "../../Components/header";
import Footer from "../../Components/Footer";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {newsByCategory, newsBySearch} from "../../Redux/newsByCategory/newsByCategoryThunk";
import {
    leftSideByCategory,
    leftSideSecound,
    rightSideData,
    rightSideFour,
    rightSideSecound,
    rightSideThird
} from "../../Redux/leftSideData/leftSideDataThunk";
import {Pagination} from "antd";
import "./index.css";

export default function HomePage() {
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const centerData = useSelector(store => store?.newsByCategory?.centerData)
    const searchdata = useSelector(store => store)
    const rightSide = useSelector(store => store?.leftSideData?.rightSide)
    const rightSideSecoundData = useSelector(store => store?.leftSideData?.rightSideSecound)
    const rightSideThirdData = useSelector(store => store?.leftSideData?.rightSideThird)
    const isSearch = searchdata?.newsByCategory?.newsBySearch && searchdata?.newsByCategory?.newsBySearch[0]?.data
    const mainCardDetails = isSearch?.length ? isSearch[0] : centerData?.length ? centerData[0] : false;
    const smallCardDetails = isSearch?.length ? isSearch : centerData?.length ? centerData?.slice(1) : false;
    const allSecoundSmall = searchdata?.leftSideData?.leftSideSecound?.data;
    const allThirdSmall = searchdata?.leftSideData?.leftSide?.data;
    const secoundSmallCard = allSecoundSmall?.length ? allSecoundSmall?.slice(1) : false;
    const thirdSmallCard = allThirdSmall?.length ? allThirdSmall?.slice(1) : false;
    const searchedData = searchdata?.newsByCategory?.newsBySearch;
    const rightSideFourth = useSelector(store => store?.leftSideData?.rightSideFour)
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState();
    useEffect(() => {
        dispatch(newsByCategory({page: "1", limit: "10", latest: "Latest"}))
        dispatch(leftSideByCategory({page: "1", limit: "20", type: "Entertainment"}))
        dispatch(leftSideSecound({page: "1", limit: "20", type: "India"}))
        dispatch(rightSideData({page: "1", limit: "1", type: "World"}))
        dispatch(rightSideSecound({page: "1", limit: "1", type: "Sports"}))
        dispatch(rightSideThird({page: "1", limit: "1", type: "Lifestyle"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Entertainment"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Tech"}))
    }, [])


    const viewAllHandle = (type) => {
        if (type === 'Home') {
            navigate("/")
        } else {
            navigate(`/${type}`)
        }
        window.scrollTo(0, 0)
    }

    const onChangePagination = async (page, sizePage) => {
        setPage(page)
        setPageSize(sizePage)
        await dispatch(newsBySearch({page: page, limit: 10, name: search ? search : location?.state?.search}))
        await window.scrollTo(0, 0)
    }

    return (
        <>
            <Header viewAllHandle={viewAllHandle} searched={location?.state?.search} page={page}
                    setSearchPagination={setSearch}/>
            <Helmet>
                {/*<title>here ypu can title </title>*/}
                <meta name='description' content='this is news site for open talks'/>
                <meta name='keywords' content='news,games'/>
            </Helmet>
            <div>
                <div className="main-part">
                    {/* <div className="side-bar-of-category">
          <div className="left-cards">
            <div class="leftside-first">
              <h5>Game News</h5>
            </div>
            {leftFirstData?.data?.length?leftFirstData?.data?.map((i,index)=>{
              return (index<4&&
              <SmallCard item={i}/>)
            }):""}
            <div className="view-all" onClick={()=>{viewAllHandle("Game")}}>
              <h5>{"View All >"}</h5>
            </div>
          </div>
          <div className="left-cards sticky" >
          <div class="leftside-first ">
            <h5>India</h5>
          </div>
          {leftSecoundData?.data?.length?leftSecoundData?.data?.map((i,index)=>{
            return (index<4&&
            <SmallCard item={i}/>)
          }):""}
          <div className="view-all" onClick={()=>{viewAllHandle("India")}}>
            <h5>{"View All >"}</h5>
          </div>
        </div>
        </div> */}
                    <div className="center-main">
                        <div className="center" style={isSearch?.length ? {paddingTop: "10px"} : {}}>
                            {!isSearch?.length ? <div class="center-heading">
                                <h5>Latest News</h5>
                            </div> : ""}
                            {!isSearch?.length ? <MainCard item={mainCardDetails}/> : ""}
                            {smallCardDetails?.length ? smallCardDetails.map((i, index) => {
                                return (<ImgCard viewAllHandle={viewAllHandle} item={i}/>)
                            }) : ""}
                            {!isSearch?.length && allSecoundSmall?.length ? <div class="center-heading">
                                <h5>India News</h5>
                            </div> : ""}
                            {!isSearch?.length && allSecoundSmall?.length ?
                                <MainCard item={allSecoundSmall?.length ? allSecoundSmall[0] : ""}/> : ""}
                            {(searchdata?.leftSideData?.leftSideSecound?.data?.length && !isSearch?.length) ? secoundSmallCard?.map((i, index) => {
                                return (<ImgCard viewAllHandle={viewAllHandle} item={i}/>)
                            }) : ""}
                            {!isSearch?.length && allThirdSmall?.length ? <div class="center-heading">
                                <h5>Entertainment</h5>
                            </div> : ""}
                            {!isSearch?.length && allThirdSmall?.length ?
                                <MainCard item={allThirdSmall?.length ? allThirdSmall[0] : ""}/> : ""}
                            {(searchdata?.leftSideData?.leftSide?.data?.length && !isSearch?.length) ? thirdSmallCard?.map((i, index) => {
                                return (<ImgCard viewAllHandle={viewAllHandle} item={i}/>)
                            }) : ""}
                            {isSearch?.length ? <Pagination className="pagination" current={page}
                                                            total={searchedData && searchedData[0]?.count}
                                                            pageSize={pageSize} onChange={onChangePagination}/> : ""}
                        </div>
                    </div>
                    <div className="side-bar-of-news">
                        <div className="left-cards sticky">
                            <div>
                                <div class="leftside-first">
                                    <h5>Top News</h5>
                                </div>
                                {rightSide?.data?.length ? rightSide?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div>
                            {rightSideSecoundData?.data?.length ?
                                <div>
                                    {rightSideSecoundData?.data?.length ? rightSideSecoundData?.data?.map((i, index) => {
                                        return (index < 4 &&
                                            <ImgCard leftSide={true} item={i}/>)
                                    }) : ""}
                                </div> : ""}
                            {rightSideThirdData?.data?.length ? <div>
                                {rightSideThirdData?.data?.length ? rightSideThirdData?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                            {rightSideFourth?.length ? <div>
                                {rightSideFourth?.length ? rightSideFourth?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
