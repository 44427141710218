import "./index.css";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    rightSideData,
    rightSideFour,
    rightSideSecound,
    rightSideThird
} from "../../Redux/leftSideData/leftSideDataThunk";
import Header from "../../Components/header";
import ImgCard from "../../Components/ImgCard";
import Footer from "../../Components/Footer";

export default function TermsAndConditions() {
    const dispatch = useDispatch()
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const navigate = useNavigate();
    const searchdata = useSelector(store => store)
    const rightSide = useSelector(store => store?.leftSideData?.rightSide)
    const rightSideSecoundData = useSelector(store => store?.leftSideData?.rightSideSecound)
    const rightSideThirdData = useSelector(store => store?.leftSideData?.rightSideThird)
    const isSearch = searchdata?.newsByCategory?.newsBySearch && searchdata?.newsByCategory?.newsBySearch[0]?.data
    const rightSideFourth = useSelector(store => store?.leftSideData?.rightSideFour)

    useEffect(() => {
        dispatch(rightSideData({page: "1", limit: "1", type: "World"}))
        dispatch(rightSideSecound({page: "1", limit: "1", type: "Sports"}))
        dispatch(rightSideThird({page: "1", limit: "1", type: "Lifestyle"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Entertainment"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Tech"}))
        window.scrollTo(0, 0)
    }, [])

    const viewAllHandle = (type) => {
        if (type === 'Home') {
            navigate("/")
        } else {
            navigate(`/${type}`)
        }
        window.scrollTo(0, 0)
    }
    return (
        <>
            <Header viewAllHandle={viewAllHandle} searched={location?.state?.search} page={page}
                    setSearchPagination={setSearch}/>
            <div>
                <div className="main-part">
                    <div className="center-main">
                        <div className="center">
                            <div className="center-heading">
                                <h5>Terms and Conditions</h5>
                            </div>
                            <div className="p-5 text-left">
                                <h2>Last updated: June 19, 2023</h2>
                                <p>Please read these terms and conditions carefully before using Our Service.</p>

                                <h2>Interpretation and Definitions</h2>
                                <hr/>
                                <h3>Interpretation</h3>
                                <p>The words of which the initial letter is capitalized have meanings defined
                                    under the following conditions. The following definitions shall have the same
                                    meaning regardless of whether they appear in singular or in plural.</p>

                                <h3>Definitions</h3>
                                <ul>
                                    <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is
                                        under
                                        common control with a party, where "control" means ownership of 50% or
                                        more of the shares, equity interest or other securities entitled to vote
                                        for election of directors or other managing authority.
                                    </li>
                                    <li><strong>Country</strong> refers to: Gujarat, India</li>
                                    <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or
                                        "Our" in this
                                        Agreement) refers to Intalkss.
                                    </li>
                                    <li><strong>Device</strong> means any device that can access the Service such as a
                                        computer, a
                                        cellphone or a digital tablet.
                                    </li>
                                    <li><strong>Service</strong> refers to the Website.</li>
                                    <li><strong>Terms and Conditions</strong> (also referred as "Terms") mean these
                                        Terms and
                                        Conditions that form the entire agreement between You and the Company
                                        regarding the use of the Service.
                                    </li>
                                    <li><strong>Third-party Social Media Service</strong> means any services or content
                                        (including
                                        data, information, products or services) provided by a third-party that
                                        may be displayed, included or made available by the Service.
                                    </li>
                                    <li><strong>Website</strong> refers to Intalkss, accessible from <a
                                        href="https://www.intalkss.com/">https://www.intalkss.com/</a></li>
                                    <li><strong>You</strong> means the individual accessing or using the Service, or the
                                        company,
                                        or other legal entity on behalf of which such individual is accessing or
                                        using the Service, as applicable.
                                    </li>
                                </ul>

                                <h2>Cookies</h2>
                                <hr/>
                                <p>We employ the use of cookies. By accessing intalkss, you agreed to use cookies in
                                    agreement with the
                                    classymedia20's Privacy Policy.</p>
                                <p>Most interactive websites use cookies to let us retrieve the user's details for each
                                    visit. Cookies
                                    are used
                                    by our website to enable the functionality of certain areas to make it easier for
                                    people visiting
                                    our website.
                                    Some of our affiliate/advertising partners may also use cookies.</p>

                                <h2>License</h2>
                                <hr/>
                                <p>Unless otherwise stated, classymedia20 and/or its licensors own the intellectual
                                    property rights for
                                    all
                                    material on intalkss. All intellectual property rights are reserved. You may access
                                    this from
                                    intalkss for your
                                    own personal use subjected to restrictions set in these terms and conditions.</p>
                                <p>You must not:</p>
                                <ul>
                                    <li>Republish material from intalkss</li>
                                    <li>Sell, rent or sub-license material from intalkss</li>
                                    <li>Reproduce, duplicate or copy material from intalkss</li>
                                    <li>Redistribute content from intalkss</li>
                                </ul>
                                <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created
                                    with the help of
                                    the Free
                                    Terms and Conditions Generator.</p>

                                <h2>Content Liability</h2>
                                <hr/>
                                <p>We shall not be hold responsible for any content that appears on your Website. You
                                    agree to protect
                                    and defend
                                    us against all claims that is rising on your Website. No link(s) should appear on
                                    any Website that
                                    may be
                                    interpreted as libelous, obscene or criminal, or which infringes, otherwise
                                    violates, or advocates
                                    the
                                    infringement or other violation of, any third party rights.</p>

                                <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
                                <hr/>
                                <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults
                                    and defects without warranty of any kind. To the maximum extent permitted
                                    under applicable law, the Company, on its own behalf and on behalf of its
                                    Affiliates and its and their respective licensors and service providers,
                                    expressly disclaims all warranties, whether express, implied, statutory or
                                    otherwise, with respect to the Service, including all implied warranties of
                                    merchantability, fitness for a particular purpose, title and non-infringement,
                                    and warranties that may arise out of course of dealing, course of performance,
                                    usage or trade practice. Without limitation to the foregoing, the Company
                                    provides no warranty or undertaking, and makes no representation of any kind
                                    that the Service will meet Your requirements, achieve any intended results, be
                                    compatible or work with any other software, applications, systems or services,
                                    operate without interruption, meet any performance or reliability standards or
                                    be error free or that any errors or defects can or will be corrected.</p>
                                <p>Without limiting the foregoing, neither the Company nor any of the company's
                                    provider makes any representation or warranty of any kind, express or implied:
                                    (i) as to the operation or availability of the Service, or the information,
                                    content, and materials or products included thereon; (ii) that the Service
                                    will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or
                                    currency of any information or content provided through the Service; or (iv)
                                    that the Service, its servers, the content, or e-mails sent from or on behalf
                                    of the Company are free of viruses, scripts, trojan horses, worms, malware,
                                    timebombs or other harmful components.</p>
                                <p>Some jurisdictions do not allow the exclusion of certain types of warranties
                                    or limitations on applicable statutory rights of a consumer, so some or all of
                                    the above exclusions and limitations may not apply to You. But in such a case
                                    the exclusions and limitations set forth in this section shall be applied to
                                    the greatest extent enforceable under applicable law.</p>

                                <h2>Severability and Waiver</h2>
                                <hr/>
                                <h3>Severability</h3>
                                <p>If any provision of these Terms is held to be unenforceable or invalid, such
                                    provision will be changed and interpreted to accomplish the objectives of such
                                    provision to the greatest extent possible under applicable law and the
                                    remaining provisions will continue in full force and effect.</p>
                                <h3>Waiver</h3>
                                <p>Except as provided herein, the failure to exercise a right or to require
                                    performance of an obligation under these Terms shall not effect a party's
                                    ability to exercise such right or require such performance at any time
                                    thereafter nor shall the waiver of a breach constitute a waiver of any
                                    subsequent breach.</p>

                                <h2>Translation Interpretation</h2>
                                <hr/>
                                <p>These Terms and Conditions may have been translated if We have made them
                                    available to You on our Service. You agree that the original English text
                                    shall prevail in the case of a dispute.</p>

                                <h2>Changes to These Terms and Conditions</h2>
                                <hr/>
                                <p>We reserve the right, at Our sole discretion, to modify or replace these Terms
                                    at any time. If a revision is material We will make reasonable efforts to
                                    provide at least 30 days' notice prior to any new terms taking effect. What
                                    constitutes a material change will be determined at Our sole discretion.</p>
                                <p>By continuing to access or use Our Service after those revisions become
                                    effective, You agree to be bound by the revised terms. If You do not agree to
                                    the new terms, in whole or in part, please stop using the website and the
                                    Service.</p>

                                <h2>Contact Us</h2>
                                <hr/>
                                <p>If you have any questions about these Terms and Conditions, You can contact
                                    us:</p>
                                <ul>
                                    <li>By email: intalkss24@gmail.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="side-bar-of-news">
                        <div className="left-cards sticky">
                            <div>
                                <div className="leftside-first">
                                    <h5>Top News</h5>
                                </div>
                                {rightSide?.data?.length ? rightSide?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div>
                            {rightSideSecoundData?.data?.length ?
                                <div>
                                    {rightSideSecoundData?.data?.length ? rightSideSecoundData?.data?.map((i, index) => {
                                        return (index < 4 &&
                                            <ImgCard leftSide={true} item={i}/>)
                                    }) : ""}
                                </div> : ""}
                            {rightSideThirdData?.data?.length ? <div>
                                {rightSideThirdData?.data?.length ? rightSideThirdData?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                            {rightSideFourth?.length ? <div>
                                {rightSideFourth?.length ? rightSideFourth?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
