
export default {
    login: () => `user/login`,
    getAllCategory: () => `category/getallcategory`,
    postCategory: () => `category/createcategory`,
    editCategory: (id) => `category/editcategory/${id}`,
    deleteCategory: () => `category/deletecategory`,
    getAllAdmin: () => `user/getalluser`,
    postAdmin: () => `user/createuser`,
    deleteUser: () => `user/deleteuser`,
    postNews: () => `news/createnews`,
    getNewsByCategory: (page,limit,category) => `news/getnews?page=${page}&limit=${limit}&category=${category}`,
    getNewsBySubCategory: (page,limit,category) => `news/getnews?page=${page}&limit=${limit}&subcat=${category}`,
    getLatestNews: (page,limit) => `news/getnews?page=${page}&limit=${limit}&letest=true`,
    getNewsById: (id) => `news/getnewsbyid/${id}`,
    deleteNews: (id) => `news/deletenews/${id}`,
    getMyNews: (page,limit,author) => `news/getnews?page=${page}&limit=${limit}&author=${author}`,
    getNewsBySearch: (page,limit,name) => `news/searchnews/${name}?page=${page}&limit=${limit}`,
};