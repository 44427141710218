import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import ImgCard from "../../Components/ImgCard";
import Header from "../../Components/header";
import { Pagination, Typography } from "antd";
import Title from "antd/es/typography/Title";
import {Helmet} from "react-helmet";
import { newsByCategory } from "../../Redux/newsByCategory/newsByCategoryThunk";
import { rightSideData, rightSideFour, rightSideSecound, rightSideThird } from "../../Redux/leftSideData/leftSideDataThunk";
import Footer from "../../Components/Footer";
import MainCard from "../../Components/MainCard";
export default function CategoryPage() {
    const navigate = useNavigate();
    const path = useParams();
    const { Text } = Typography;
    const dispatch = useDispatch()
    const rightSideSecoundData = useSelector(store=>store?.leftSideData?.rightSideSecound)
    const rightSideThirdData = useSelector(store=>store?.leftSideData?.rightSideThird)
    const rightSideFourth = useSelector(store=>store?.leftSideData?.rightSideFour)
    const [page,setPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);
    const centerData = useSelector(store=>store?.newsByCategory?.centerData)
    const rightSide = useSelector(store=>store?.leftSideData?.rightSide)
    const categoryCards = centerData?.data?.length?centerData?.data.slice(1):""; 

    useEffect(()=>{
      if(path?.category){
          if(path?.subCat){
              dispatch(newsByCategory({page:page,limit:20,type:path?.subCat,sub: true}))

          }else {
              dispatch(newsByCategory({page:page,limit:20,type:path?.category}))
          }
        dispatch(rightSideData({page:"1",limit:"1",type:"World"}))
        dispatch(rightSideSecound({page:"1",limit:"1",type:"Sports"}))
        dispatch(rightSideThird({page:"1",limit:"1",type:"Lifestyle"}))
        !(rightSideFourth?.length>=2)&&dispatch(rightSideFour({page:"1",limit:"1",type:"Entertainment"}))
        !(rightSideFourth?.length>=2)&&dispatch(rightSideFour({page:"1",limit:"1",type:"Tech"}))
      }
    },[path])
    
    const viewAllHandle = (type) => {
      if(type==='Home'){
        navigate("/")
      }else{
        navigate(`/${type}`)
        dispatch(newsByCategory({page:page,limit:"20",type:type}))
      }
      window.scrollTo(0,0)
    }
    
    const onChangePagination = (page,pageSize) => {
      setPageSize(pageSize);
      setPage(page);
      dispatch(newsByCategory({page:page,limit:pageSize,type:path?.category}))
      window.scrollTo(0,0)
    }

  return (
  <>
    <Header viewAllHandle={viewAllHandle}/>
    <Helmet>
      {/*<title>here ypu can title </title>*/}
      <meta name='description' content='this is nothing'/>
      <meta name='keywords' content='news,games'/>
    </Helmet>
    <div>
      <div className="main-part">
        <div className="center-main">
          <div className="center">
            <div class="center-heading">
              <h5>{path?.subCat ? path?.subCat :path?.category} News</h5>
            </div>
            <div className="w-11/12 ml-2.5 text-left"><Title onClick={()=>{navigate("/")}} className="cursor-pointer inline-block" level={5}>Home / </Title><Text style={{cursor: 'pointer'}} onClick={() => { path?.subCat && navigate(`/${path?.category}`) }} > {path?.category}</Text>{
                path?.subCat ? <Text> / {path?.subCat}</Text> : null}</div>
            {centerData?.data?.length?<MainCard item={centerData?.data[0]} />:""}
            {categoryCards?.length?categoryCards?.map((i,index)=>{
              return (<ImgCard viewAllHandle={viewAllHandle} item={i}/>)
            }):""}
            <Pagination className="pagination" current={page} total={centerData?.count?centerData?.count[0]:"10"} pageSize={pageSize} onChange={onChangePagination}/>
          </div>
        </div>
        <div className="side-bar-of-news">
        <div className="left-cards sticky">
            <div>
            <div class="leftside-first">
              <h5>Top News</h5>
            </div>
            {rightSide?.data?.length?rightSide?.data?.map((i,index)=>{
              return (index<4&&
                <ImgCard leftSide={true} item={i}/>)
              }):""}
              </div>
          {rightSideSecoundData?.data?.length?
          <div >
            {rightSideSecoundData?.data?.length?rightSideSecoundData?.data?.map((i,index)=>{
              return (index<4&&
              <ImgCard leftSide={true} item={i}/>)
            }):""}
          </div>:""}
          {rightSideThirdData?.data?.length?<div >
            {rightSideThirdData?.data?.length?rightSideThirdData?.data?.map((i,index)=>{
              return (index<4&&
              <ImgCard leftSide={true} item={i}/>)
            }):""}
          </div>:""}
          {rightSideFourth?.length?<div >
            {rightSideFourth?.length?rightSideFourth?.map((i,index)=>{
              return (index<4&&
              <ImgCard leftSide={true} item={i}/>)
            }):""}
          </div>:""}
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  );
}
