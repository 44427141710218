import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api/api";
import { apiEndPoints } from "../../api";

export const leftSideByCategory = createAsyncThunk('leftSideByCategory',async (payload) => {
  try {
    const data = await api.get(payload?.latest?apiEndPoints.getLatestNews(payload?.page,payload?.limit):apiEndPoints.getNewsByCategory(payload?.page,payload?.limit,payload?.type))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
export const leftSideSecound = createAsyncThunk('leftSideSecound',async (payload) => {
  try {
    const data = await api.get(payload?.latest?apiEndPoints.getLatestNews(payload?.page,payload?.limit):apiEndPoints.getNewsByCategory(payload?.page,payload?.limit,payload?.type))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
export const rightSideData = createAsyncThunk('rightSideData',async (payload) => {
  try {
    const data = await api.get(apiEndPoints.getNewsByCategory(payload?.page,payload?.limit,payload?.type))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
export const rightSideSecound = createAsyncThunk('rightSideSecound',async (payload) => {
  try {
    const data = await api.get(apiEndPoints.getNewsByCategory(payload?.page,payload?.limit,payload?.type))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
export const rightSideThird = createAsyncThunk('rightSideThird',async (payload) => {
  try {
    const data = await api.get(apiEndPoints.getNewsByCategory(payload?.page,payload?.limit,payload?.type))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
export const rightSideFour = createAsyncThunk('rightSideFour',async (payload) => {
  try {
    const data = await api.get(apiEndPoints.getNewsByCategory(payload?.page,payload?.limit,payload?.type))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
