import {MenuOutlined, SearchOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import "./index.css";
import {Drawer} from "antd";
import Logo from '../../assets/intalkss_logo.png'
import {newsBySearch} from "../../Redux/newsByCategory/newsByCategoryThunk";
import {getAllCategory} from "../../Redux/category/categotyThunk";

export default function Header({viewAllHandle, searched, page, setSearchPagination}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector(state => state);
    const [search, setSearch] = useState(searched && store?.newsByCategory?.newsBySearch ? searched : null);
    const categoryStore = useSelector(state => state?.category?.category);
    const [open, setOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const path = useParams();
    const [subCategory, setSubCategory] = useState([]);

    const showDrawer = () => {
        setOpen(!open);
    };

    const openSearch = (e) => {
        if (searchOpen && search) {
            handleSubmitInput(e)
        } else {
            setSearchOpen(!searchOpen);
        }
    };

    const handleSubmitInput = async (event) => {
        event.preventDefault();
        if (search) {
            await dispatch(newsBySearch({page: page, limit: 10, name: search?.toLowerCase()}))
            await navigate("/", {state: {search: search}})
        } else {
            toast.warning('Please add Topic')
        }
    };

    useEffect(() => {
        dispatch(getAllCategory())
    }, [])
    useEffect(() => {
        !search && dispatch(newsBySearch())
    }, [search])
    useEffect(() => {
        const subCat = categoryStore?.filter(item => item?.name === path.category) || [];
        setSubCategory(subCat[0]?.subCat);
    }, [categoryStore, path])
    return (
        <header className="home-header ">
            <div className="header bg-[#121727] text-white">
                <div className="border py-3 px-6">
                    <div className="flex justify-between">
                            <div className="flex items-center menu-btn mr-3" onClick={showDrawer}><MenuOutlined/></div>
                        <div className='flex'>
                            <div className="flex items-center Reporint" onClick={() => navigate("/")}>
                                <img src={Logo} alt="" width='100px' style={{cursor: "pointer"}}/>
                            </div>
                        </div>

                        {/* <div className="ml-6 flex rounded-md flex-1 game gap-x-4 border border-[#DDE2E4] bg-white">
                            <input
                                type="text"
                                className="w-full px-3 py-2 text-sm text-black search-input"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e?.target?.value);
                                    setSearchPagination(e?.target?.value)
                                }}
                            />
                            <div onClick={handleSubmitInput}
                                 className="flex cursor-pointer bg-[#131627] rounded-md   items-center gap-x-1  py-2 px-4 hover:bg-white hover:text-black">
                                <SearchOutlined/>
                                <span className="text-sm font-medium">Search</span>
                            </div>
                        </div> */}
                        <div className="ml-2 flex">
                            <div onClick={openSearch}
                                 className='ml-2 flex items-center cursor-pointer items-center rounded-md border py-0.5 px-3 hover:bg-gray-100 hover:text-black mobile-search'>
                                <SearchOutlined/>
                            </div>
                            {/* <div
                                className="ml-2 flex cursor-pointer items-center gap-x-1 rounded-md border py-2 px-3 sm:px-4 hover:bg-gray-100 hover:text-black">
                                <span onClick={() => {
                                    navigate("/login")
                                }} className="text-sm font-medium ">Sign in</span>
                            </div> */}
                        </div>
                    </div>
                    {searchOpen ? <div className="mt-2 flex rounded-md flex-1 gap-x-4 border border-[#DDE2E4] bg-white">
                        <form action="" className='w-full' onSubmit={handleSubmitInput}>
                            <input
                                type="text"
                                className="w-full rounded-md px-3 py-2 text-sm text-black"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e?.target?.value);
                                    setSearchPagination(e?.target?.value);
                                }}
                            />
                        </form>

                    </div> : null}
                    <div className="mt-4 flex items-center justify-between w-full custom-scroll"
                         style={{margin: "10px auto 0px", overflowX: "scroll", paddingBottom: "8px"}}>
                        <div className="flex gap-x-4" style={{width: "auto", margin: "0 auto"}}>
                            {categoryStore?.length ? categoryStore?.map((item) => (
                                <span style={{width: "max-content"}}
                                      className={path?.category === item.name ? "cursor-pointer rounded-sm py-1 px-2 text-sm font-medium bg-gray-100 text-black" : "cursor-pointer rounded-sm py-1 px-2 text-sm text-white font-medium hover:text-black hover:bg-gray-100"}
                                      onClick={() => {
                                          viewAllHandle(item.name)
                                      }}>
                  {item?.name}
                </span>
                            )) : "Welcome!"}
                        </div>
                    </div>
                </div>
                {subCategory?.length ? <div className='sub_group bg-white flex overflow-x-scroll justify-center'
                                            style={{boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"}}>
                    {subCategory?.length ? subCategory?.map((item) => (
                        <>
                            <span style={{width: "max-content", cursor: "pointer"}}
                                  className={`${path?.subCat === item && "sub-cat-btn-active"} sub-cat-btn p-1`}
                                  onClick={() => {
                                      navigate(`/${path.category}/${item}/sub`)
                                  }}>
                          {item}
                        </span>
                        </>
                    )) : "Welcome!"}
                </div> : null}
                <Drawer closable={false} title={<>
                    <div className="flex items-center" onClick={() => {
                        navigate("/");
                        showDrawer();
                    }}>
                        <img src={Logo} alt="" width='100px'/>
                    </div>
                </>} width='250px' style={{background: '#121727'}} placement="left" onClose={showDrawer} open={open}>
                    {categoryStore?.length ? categoryStore?.map((item) => (
                        <div style={{width: "max-content"}}
                             className="cursor-pointer rounded-sm py-1 px-2 text-sm text-white font-medium hover:text-black hover:bg-gray-100"
                             onClick={() => {
                                 viewAllHandle(item.name);
                                 showDrawer();
                             }}>
                            {item?.name}
                        </div>
                    )) : "Welcome!"}
                </Drawer>
            </div>
        </header>
    )
}