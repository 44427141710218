import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { api, apiEndPoints } from "../../api";

export const getAllAdmin = createAsyncThunk('get admin', async () => {
  try {
    const data = await api.get(apiEndPoints.getAllAdmin())
    .catch((error)=>{
      toast.error(error?.response?.data?.massage?error?.response?.data?.massage:"Get Category Fail");
      })
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})

export const postAdmin = createAsyncThunk('postAdmin', async (payload) => {
  try {
    const data = await api.post(apiEndPoints.postAdmin(),payload)
    if(data.status){
        toast.success('Create Admin Sucessfull');
      }else{
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Create Admin Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})

export const deleteUser = createAsyncThunk('deleteUser', async (id) => {
  try {
    const data = await api.post(apiEndPoints.deleteUser(),id)
    if(data.status){
      toast.error('Delete User Sucessfull');
    }else{
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Delete User Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})