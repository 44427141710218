import "./index.css";
import Header from "../../Components/header";
import ImgCard from "../../Components/ImgCard";
import Footer from "../../Components/Footer";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    rightSideData,
    rightSideFour,
    rightSideSecound,
    rightSideThird
} from "../../Redux/leftSideData/leftSideDataThunk";

export default function AboutUs() {
    const dispatch = useDispatch()
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const navigate = useNavigate();
    const searchdata = useSelector(store => store)
    const rightSide = useSelector(store => store?.leftSideData?.rightSide)
    const rightSideSecoundData = useSelector(store => store?.leftSideData?.rightSideSecound)
    const rightSideThirdData = useSelector(store => store?.leftSideData?.rightSideThird)
    const isSearch = searchdata?.newsByCategory?.newsBySearch && searchdata?.newsByCategory?.newsBySearch[0]?.data
    const rightSideFourth = useSelector(store => store?.leftSideData?.rightSideFour)

    useEffect(() => {
        dispatch(rightSideData({page: "1", limit: "1", type: "World"}))
        dispatch(rightSideSecound({page: "1", limit: "1", type: "Sports"}))
        dispatch(rightSideThird({page: "1", limit: "1", type: "Lifestyle"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Entertainment"}))
        !(rightSideFourth?.length >= 2) && dispatch(rightSideFour({page: "1", limit: "1", type: "Tech"}))
        window.scrollTo(0, 0)
    }, [])

    const viewAllHandle = (type) => {
        if (type === 'Home') {
            navigate("/")
        } else {
            navigate(`/${type}`)
        }
        window.scrollTo(0, 0)
    }
    return (
        <>
            <Header viewAllHandle={viewAllHandle} searched={location?.state?.search} page={page}
                    setSearchPagination={setSearch}/>
            <div>
                <div className="main-part">
                    <div className="center-main">
                        <div className="center">
                            <div className="center-heading">
                                <h5>About Us</h5>
                            </div>
                            <div style={{textAlign: "left", padding: "28px"}}>
                                <h3 style={{fontSize: "28px"}}>Our Story</h3>
                                <p className="mt-3">Founded in 2022, Intalkss.com is India's leading English news portal
                                    with an aim to reach out to millions of Indians in India and significantly the
                                    Indian
                                    diaspora worldwide who are eager to stay in touch with India-based news and stories
                                    in
                                    English because of the variety of content presented in its eye-pleasing design
                                    format.
                                    It has a leading position in the web market and is rapidly progressing to get the
                                    top
                                    slot. We also adhere to the tree of journalistic ethics by serving fresh news on a
                                    plate
                                    of complete authenticity and our comments remain only as a guard so that all users
                                    of
                                    our portal do not deviate from the truth.</p>
                                <p className="mt-3">Our news persons are prompt in responding to any event by collecting
                                    first hand data. Personal interviews of concerned people of that event or incident
                                    reinforce the fabric of the information not to say our questionnaires are very
                                    yielding.</p>
                                <p className="mt-3">We keep net users instantly and quickly updated with latest news
                                    covering politics, sports, entertainment, health, education, business, technology
                                    and
                                    other fields. We also cover several Indian states like Gujarat, Delhi, Rajasthan,
                                    Haryana, Himachal Pradesh, Madhya Pradesh, Maharashtra, Uttar Pradesh, Punjab with
                                    our
                                    own staff network.</p>
                            </div>
                            <div style={{textAlign: "left", padding: "28px"}}>
                                <h3 style={{fontSize: "28px"}}>Team</h3>
                                <p className="mt-3 text-lg">Meet the brilliant minds behind TechJunkie, individuals
                                    whose
                                    passion for technology and expertise contribute to making our site the unique
                                    resource
                                    it is today:</p>

                                <p className="mt-4">Moni Thakker - Moni has a decade of experience in the news field,
                                    from
                                    covering the latest devices to reviewing them and getting the best possible
                                    information
                                    out to readers.</p>
                                <p className="mt-2">Drashti Maheta - Writer: With almost half a decade of writing
                                    experience, Drashti can write any news in a format that is easy to understand and
                                    follow
                                    by people from all backgrounds.</p>
                                <p className="mt-2">Palak Tiwari - Writer: Palak has more than 6 years of writing
                                    experience
                                    in the field of news content, so he works to write blogs on such topics and provide
                                    information to our users.</p>
                                <p className="mt-2">Ajit Patel - Writer: Ajit is an investment advisor and provides
                                    information related to tech so that our users can easily find and stay informed
                                    about
                                    the news.</p>
                            </div>
                            <div style={{textAlign: "left", padding: "28px"}}>
                                <h3 style={{fontSize: "28px"}}>Quality & Editorial Standards at Intalkss</h3>
                                <p className="mt-3">At Intalkss, every article we publish is subject to rigorous
                                    scrutiny to
                                    ensure its quality — but our commitment doesn't stop there. Rather than simply
                                    publish
                                    and forget, we have instituted a system of continuous review. Each part undergoes
                                    thorough testing for accuracy upon initial release.</p>
                                <p className="mt-3">However, our editorial standards extend beyond the initial
                                    publication
                                    date, with each article undergoing a rolling review for accuracy, updates,
                                    screenshot
                                    verification and cross-device compatibility. In this way, we maintain our commitment
                                    to
                                    quality, accuracy and relevance in a fast-paced, evolving technological world.</p>
                            </div>
                            <div style={{textAlign: "left", padding: "28px"}}>
                                <h3 style={{fontSize: "28px"}}>We are Free to Access & User-Supported</h3>
                                <p className="mt-3">Intalkss.com is proud to provide free access to users worldwide, and
                                    we
                                    greatly appreciate our reader community who actively share our content on various
                                    social
                                    media platforms. Your continued support helps expand the reach of Intalkss and
                                    allows us
                                    to keep our content accessible, avoid paywalls and paid subscriptions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="side-bar-of-news">
                        <div className="left-cards sticky">
                            <div>
                                <div className="leftside-first">
                                    <h5>Top News</h5>
                                </div>
                                {rightSide?.data?.length ? rightSide?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div>
                            {rightSideSecoundData?.data?.length ?
                                <div>
                                    {rightSideSecoundData?.data?.length ? rightSideSecoundData?.data?.map((i, index) => {
                                        return (index < 4 &&
                                            <ImgCard leftSide={true} item={i}/>)
                                    }) : ""}
                                </div> : ""}
                            {rightSideThirdData?.data?.length ? <div>
                                {rightSideThirdData?.data?.length ? rightSideThirdData?.data?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                            {rightSideFourth?.length ? <div>
                                {rightSideFourth?.length ? rightSideFourth?.map((i, index) => {
                                    return (index < 4 &&
                                        <ImgCard leftSide={true} item={i}/>)
                                }) : ""}
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
