import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import ImgCard from "../../Components/ImgCard";
import Header from "../../Components/header";
import Typography from "antd/es/typography/Typography";
import Title from "antd/es/typography/Title";
import { Divider, Spin } from "antd";
import { Helmet } from "react-helmet";
import {
  getNewsById,
  newsByCategory,
} from "../../Redux/newsByCategory/newsByCategoryThunk";
import {
  leftSideSecound,
  rightSideData,
  rightSideFour,
  rightSideSecound,
  rightSideThird,
} from "../../Redux/leftSideData/leftSideDataThunk";
import Footer from "../../Components/Footer";
import { months } from "../AdminCategory/Constant/constant";
export default function NewsPage() {
  const navigate = useNavigate();
  const { Text } = Typography;
  const centerData = useSelector((store) => store?.newsByCategory?.centerData);
  const mainNews = useSelector((store) => store?.newsByCategory);
  const indiaNews = useSelector(
    (store) => store?.leftSideData?.leftSideSecound
  );
  const rightSide = useSelector((store) => store?.leftSideData?.rightSide);
  const leftSecoundData = useSelector(
    (store) => store?.leftSideData?.leftSideSecound
  );
  const rightSideSecoundData = useSelector(
    (store) => store?.leftSideData?.rightSideSecound
  );
  const rightSideThirdData = useSelector(
    (store) => store?.leftSideData?.rightSideThird
  );
  const rightSideFourth = useSelector(
    (store) => store?.leftSideData?.rightSideFour
  );
  const path = useParams();
  const dispatch = useDispatch();
  
  function dateFormate() {
    const date = new Date(mainNews?.centerMainNews?.createdAt);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    const formattedDate = month + " " + day + ", " + year;
    const formattedTime = hours + ":" + minutes;
    const formattedTimeZone = "IST";
  
    return formattedDate + " " + formattedTime + " " + formattedTimeZone;
  }

  useEffect(() => {
    dispatch(getNewsById(path?.id));
    dispatch(newsByCategory({ page: "1", limit: "20", type: path?.category }));
    // dispatch(leftSideByCategory({page:"0",limit:"4",type:"Game"}))
    dispatch(leftSideSecound({ page: "1", limit: "20", type: "India" }));
    dispatch(rightSideData({ page: "1", limit: "1", type: "World" }));
    dispatch(rightSideSecound({ page: "1", limit: "1", type: "Sports" }));
    dispatch(rightSideThird({ page: "1", limit: "1", type: "Lifestyle" }));
    !(rightSideFourth?.length >= 2) &&
      dispatch(rightSideFour({ page: "1", limit: "1", type: "Entertainment" }));
    !(rightSideFourth?.length >= 2) &&
      dispatch(rightSideFour({ page: "1", limit: "1", type: "Tech" }));

    window.scrollTo(0, 0);
  }, [path]);

  function toSentenceCase(str) {
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
  }
  const viewAllHandle = (type) => {
    if (type === "Home") {
      navigate("/");
    } else {
      navigate(`/${type}`);
    }
  };
  const authorClickHandle = (name) => {
    navigate(`/author/${name}`);
  };

  return (
    <>
      <Header viewAllHandle={viewAllHandle} />
      <Helmet>
        {/*<title>here ypu can title </title>*/}
        <meta name="description" content={mainNews?.centerMainNews?.title} />
        <meta name="keywords" content={mainNews?.centerMainNews?.keyWord} />
      </Helmet>
      <div>
        <div className="main-part">
          <div className="center-main">
            <div className="center">
              {mainNews?.centerMainNews?.title?<div className="main-news">
                <div className="w-100 ml-2.5 text-left p-1.5">
                  <Title
                    className="cursor-pointer inline-block full-page-desc"
                    onClick={() => navigate("/")}
                    level={5}
                  >
                    Home /
                  </Title>{" "}
                  <Title
                    onClick={() =>
                      viewAllHandle(mainNews?.centerMainNews?.category)
                    }
                    className="cursor-pointer inline-block full-page-desc"
                    level={5}
                  >
                    {mainNews?.centerMainNews?.category} /
                  </Title>
                  {mainNews?.centerMainNews?.subCat ? (
                    <Title
                      onClick={() =>
                        navigate(
                          `/${mainNews?.centerMainNews?.category}/${mainNews?.centerMainNews?.subCat}/sub`
                        )
                      }
                      className="cursor-pointer inline-block full-page-desc"
                      level={5}
                    >
                      {mainNews?.centerMainNews?.subCat} /
                    </Title>
                  ) : null}
                  <Text className="full-page-desc">
                    {" "}
                    {mainNews?.centerMainNews?.title[0]!==mainNews?.centerMainNews?.title[0].toUpperCase()
                      ? toSentenceCase(mainNews?.centerMainNews?.title)
                      : mainNews?.centerMainNews?.title}
                  </Text>
                </div>
                <Title
                  className="inline-block text-left px-4 full-page-title"
                  style={{ width: "100%" }}
                  level={4}
                >
                  {mainNews?.centerMainNews?.title
                    ? toSentenceCase(mainNews?.centerMainNews?.title)
                    : ""}
                </Title>
                <div className="px-4 text-left">
                  <Text>
                    {" "}
                    by{" "}
                    <a
                      onClick={() =>
                        authorClickHandle(mainNews?.centerMainNews?.author)
                      }
                    >
                      {mainNews?.centerMainNews?.author}
                    </a>
                  </Text>
                </div>
                <Divider
                  className="px-4 max-w-[95%] min-w-[95%] w-[95%]"
                  style={{
                    margin: "17px auto 5px",
                    backgroundColor: "#dbdbdb",
                  }}
                />
                <div className="px-4 text-left">
                  <Text className="text-left  full-page-desc" type="secondary">
                    {dateFormate() ? dateFormate() : ""}
                  </Text>
                </div>
                <div className="px-4 text-left text-[#424242] pt-2.5">
                  <Text className="text-base  full-page-desc">
                    {mainNews?.centerMainNews?.smallDescription}
                  </Text>
                </div>
                <div className="news-img-box">
                  <img
                    className="min-w-full"
                    src={mainNews?.centerMainNews?.imageUrl}
                  />
                </div>
                <div className="px-4 text-left text-[#424242] pt-2.5">
                  {mainNews?.centerMainNews?.bigDescription
                    ?.split(".\n")
                    .map((i) => (
                      <Text className="block mb-4 text-base full-page-desc">
                        {i.trim()}
                        {i.trim().length
                          ? i.trim()[i.trim().length - 1] === "."
                            ? ""
                            : "."
                          : ""}{" "}
                      </Text>
                    ))}
                </div>
                <div className="news-img-box">
                  <img
                    className="min-w-full"
                    src={mainNews?.centerMainNews?.secondaryImage}
                  />
                </div>
                <div className="px-4 text-left text-[#424242] pt-2.5">
                  {mainNews?.centerMainNews?.Description3?.split(".\n").map(
                    (i) => (
                      <Text className="block mb-4 text-base full-page-desc">
                        {i.trim()}
                        {i.trim().length
                          ? i.trim()[i.trim().length - 1] === "."
                            ? ""
                            : "."
                          : ""}{" "}
                      </Text>
                    )
                  )}
                </div>
              </div>
              :<div className="news-loader"><Spin style={{paddingTop:"25vh"}}/></div>}
              <div>
                <div class="center-heading">
                  <h5>{mainNews?.centerMainNews?.category} News</h5>
                </div>
                {centerData?.data?.length && path?.category !== "India"
                  ? centerData?.data?.map((i, index) => {
                      return <ImgCard viewAllHandle={viewAllHandle} item={i} />;
                    })
                  : ""}
                {indiaNews?.data?.length
                  ? indiaNews?.data?.map((i, index) => {
                      return <ImgCard viewAllHandle={viewAllHandle} item={i} />;
                    })
                  : ""}
              </div>
            </div>
          </div>
          <div className="side-bar-of-news">
            <div className="left-cards sticky">
              <div>
                <div class="leftside-first">
                  <h5>Top News</h5>
                </div>
                {rightSide?.data?.length
                  ? rightSide?.data?.map((i, index) => {
                      return index < 4 && <ImgCard leftSide={true} item={i} />;
                    })
                  : ""}
              </div>
              {rightSideSecoundData?.data?.length ? (
                <div>
                  {rightSideSecoundData?.data?.length
                    ? rightSideSecoundData?.data?.map((i, index) => {
                        return (
                          index < 4 && <ImgCard leftSide={true} item={i} />
                        );
                      })
                    : ""}
                </div>
              ) : (
                ""
              )}
              {rightSideThirdData?.data?.length ? (
                <div>
                  {rightSideThirdData?.data?.length
                    ? rightSideThirdData?.data?.map((i, index) => {
                        return (
                          index < 4 && <ImgCard leftSide={true} item={i} />
                        );
                      })
                    : ""}
                </div>
              ) : (
                ""
              )}
              {rightSideFourth?.length ? (
                <div>
                  {rightSideFourth?.length
                    ? rightSideFourth?.map((i, index) => {
                        return (
                          index < 4 && <ImgCard leftSide={true} item={i} />
                        );
                      })
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
