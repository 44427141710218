import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api/api";
import { apiEndPoints } from "../../api";

export const getAllCategory = createAsyncThunk('loginUser', async () => {

  try {
    const data = await api.get(apiEndPoints.getAllCategory())
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})

export const postCategory = createAsyncThunk('postCategory', async (category) => {
  try {
    const data = await api.post(apiEndPoints.postCategory(),category)
    if(data.status){
      toast.success('Create Category Sucessfull');
    }else{
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Create Category Fail");
    }
    return data;
  }catch{

  }
})

export const deleteCategory = createAsyncThunk('deleteCategory', async (id) => {
  try {
    const data = await api.post(apiEndPoints.deleteCategory(),id)
    if(data.status){
      toast.error('Delete Category Sucessfull');
    }else{
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Delete Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})