import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { api, apiEndPoints } from "../../api";

export const postNews = createAsyncThunk('postNews', async (payload) => {
  try {
    const data = await api.post(apiEndPoints.postNews(),payload)
    .then((response)=> {
        toast.success(response?.data?.massage);
        return response
      })
    .catch((error)=>{
      toast.error(error?.response?.data?.massage?error?.response?.data?.massage:"Create News Fail");
      })
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})

// export const deleteUser = createAsyncThunk('deleteUser', async (id) => {
//   try {
//     const data = await api.post(apiEndPoints.deleteUser(),id)
//     .then((response)=> {
//         toast.error('Delete User Sucessfull');
//         return response 
//       })
//     .catch((error)=>{
//       toast.error(error?.response?.data?.massage?error?.response?.data?.massage:"Delete User Fail");
//       })
//     return data;
//   } catch (error) {
//     // return rejectWithValue(error.message);
//   }
// })