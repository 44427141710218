import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import Logo from '../../assets/intalkss_logo.png'
import { loginUser } from "../../Redux/user/userThunk";
import { useNavigate } from "react-router-dom";

function MainAdminLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const handleSignIn = async(data) => {
        await dispatch(loginUser({data,navigate}))
    }
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    useEffect(()=>{
        if(role&&token){
            navigate("/admin-panel")        
        }       
    },[])
  return (
    <div class="bg-white dark:bg-gray-900">
        <div class="flex justify-center h-screen">
            <div class="hidden bg-cover lg:block lg:w-2/3" style={{backgroundImage: "url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)"}}>
                <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                    <div>
                        <img src={Logo} alt="" width='200px'/>
                        <p class="max-w-xl text-gray-300">Nothing in fine print is ever good news.</p>
                    </div>
                </div>
            </div>
            
            <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                <div class="flex-1">
                    <div class="text-center">
                        <h2 class="text-4xl font-bold text-center text-gray-700 dark:text-white">Hello Again!</h2>
                        <p class="mt-3 text-gray-500 dark:text-gray-300">Sign in to access your admin account</p>
                    </div>

                    <div class="mt-8">
                        <form onSubmit={handleSubmit(handleSignIn)}>
                            <div>
                                <label for="email" class="block text-left mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                                <input type="email" {...register('email', {
                                    required: 'Email is required.',
                                    pattern: {
                                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                        message: 'Email is not valid.'
                                    }
                                })} id="email" placeholder="example@example.com" class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                {errors?.email && <div className="text-red-500 text-sm mt-1 mb-4 pl-4">{errors?.email?.message}</div>}
                            </div>

                            <div class="mt-6">
                                <div class="flex justify-between mb-2">
                                    <label for="password" class="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                    <a href="#" class="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">Forgot password?</a>
                                </div>

                                <input type="password" {...register('password', {
                                    required: "Password is reqiured ",
                                    validate: {
                                        checkLength: (value) => value.length >= 6
                                    },
                                    pattern: {
                                        value: /(?=.*\d)(?=.*[a-z])(?!.*\s)(?=.*[!@#$*])/,
                                        message: 'password is not valid.'
                                    }
                                })} placeholder="Your Password" class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                {errors?.password && <div className="text-red-500 text-sm mt-1 mb-4 pl-4">{errors?.password?.message}</div>}
                            </div>
                            <div class="mt-6">
                                <button 
                                    type="submit"
                                    class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                    Sign in
                                </button>
                            </div>

                        </form>

                        <p class="mt-6 text-sm text-center text-gray-400">You are not an Admin ? <a onClick={()=>{navigate("/")}} style={{cursor:"pointer"}} class="text-blue-500 focus:outline-none focus:underline hover:underline">home page</a>.</p>
                        <p class="mt-1 text-sm text-center text-gray-400">You want to become an Admin ? <a href="mailto:timestoday30@gmail.com" class="text-blue-500 focus:outline-none focus:underline hover:underline">Contact with us</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default MainAdminLogin;
