export const FooterCategory = [
    { category: "Home" },
    { category: "Tech" },
    { category: "India" },
    { category: "World" },
    { category: "Entertainment", subCat:['Bollywood', 'Hollywood', 'TV', 'Anime']},
]
export const Footerdetails = [
    { category: "Sports", subCat:['Cricket', 'Football'] },
    { category: "Lifestyle" ,subCat:['Fashion', 'Health', 'Travel']},
    { category: "Business" },
    { category: "Auto" },
    { category: "Education" },
  ]