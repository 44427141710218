import React ,{ useEffect, useState } from "react";
import AdminNav from "../../Components/AdminCategory";
import { Button, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../Redux/category/categotyThunk";
import { postNews } from "../../Redux/addNews/newsThunk";
import { Upload } from 'antd';
import {InboxOutlined} from "@ant-design/icons";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../storage/storage";

const { Dragger } = Upload;
export default function AdminNews() {
  const dispatch = useDispatch();
  const categoryStore = useSelector(state=>state?.category?.category);
  const formRef = React.useRef(null);
  const [subCategory,setSubCategory] = useState([]);
  useEffect(()=>{
    dispatch(getAllCategory());
  },[])
  const categories = Array.isArray(categoryStore)&&categoryStore?.map((i)=>({...i,label:i.name,value:i.name}))
  const onFinish = async(values) => {
      const name = localStorage.getItem('name') || 'admin';
      if(values?.upload2?.file?.originFileObj?.name){
          const storageRef = ref(storage, `files/${values.upload.file.originFileObj.name}${Math.floor((Math.random()*1000000)+1)}`);
          const uploadTask = uploadBytesResumable(storageRef, values.upload.file.originFileObj);
          const storageRef1 = ref(storage, `files/${values.upload2.file.originFileObj.name}${Math.floor((Math.random()*1000000)+1)}`);
          uploadBytesResumable(storageRef1, values.upload2.file.originFileObj).then((task)=>{
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  getDownloadURL(task.ref).then((url1) => {
                      dispatch(postNews({...values,keyWord: values.keyWord.split(' '),imageUrl: url,secondaryImage: url1, author: name, title: values.title}));
                      formRef.current?.resetFields();
                  });
              })
          })
      }else {
          const storageRef = ref(storage, `files/${values.upload.file.originFileObj.name}${Math.floor((Math.random()*1000000)+1)}`);
          uploadBytesResumable(storageRef, values.upload.file.originFileObj).then((task)=>{
              getDownloadURL(task.ref).then((url) => {
                  dispatch(postNews({...values,keyWord: values.keyWord.split(' '),imageUrl: url, author: name, title: values.title}));
                  formRef.current?.resetFields();
              });
          })
      }

};
  const handleSubCategory = (value) => {
      const data = categories?.filter((i) => i?.name === value);
      const subCat = data[0].subCat?.map((item) => {
          return {
              label: item, value: item
          }
      })
      setSubCategory(subCat || [])
  }
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
    const props = {
        name: 'file',
        multiple: true,
        customRequest(e) {
            e.onSuccess();
        }
    };
  return (
    <div className="w-full">
      <AdminNav />
        <div className="min-h-[93.4vh] relative overflow-hidden dark:bg-gray-900 flex justify-center items-center">
            <div
                className="absolute w-60 h-60 rounded-xl dark:bg-gray-300 -top-5 -left-16 z-0 transform rotate-45 hidden md:block">
            </div>
            <div
                className="absolute w-48 h-48 rounded-xl dark:bg-gray-300 -bottom-6 -right-10 transform rotate-12 hidden md:block">
            </div>
            <div className="py-5 px-5 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 2xl:w-1/3  dark:bg-gray-300 rounded-2xl shadow-xl z-20">
                <div>
                    <h1 className="text-3xl font-bold text-center mb-10 cursor-pointer">Add News</h1>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Headline"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input valid user Headline!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Description 1"
                            name="smallDescription"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input valid user description!",
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            label="Main Image"
                            name="upload"
                            style={{textAlign:"left"}}
                            rules={[
                                {
                                    required: true,
                                    message: "Please Upload Image",
                                },
                            ]}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined style={{ color: '#111827' }}/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item
                            label="Description 2"
                            name="bigDescription"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input valid user description!",
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            label="secondaryImage"
                            name="upload2"
                            style={{textAlign:"left"}}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined style={{ color: '#111827' }}/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item
                            label="Description 3"
                            name="Description3"
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            label="KeyWord"
                            name="keyWord"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input valid KeyWord!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Category"
                            name="category"
                            style={{textAlign:"left"}}
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select category!",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: "50%",
                                }}
                                options={categories}
                                onChange={handleSubCategory}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Sub Category"
                            name="subCat"
                            style={{textAlign:"left"}}
                        >
                            <Select
                                style={{
                                    width: "50%",
                                }}
                                options={subCategory}
                            />
                        </Form.Item>

                        <Form.Item className="buttons_of_adduser">
                            <Button
                                type="primary"
                                style={{ backgroundColor: "#1f2937", color: "#fff" }}
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div className="w-40 h-40 absolute dark:bg-gray-300 rounded-full top-0 right-12 hidden md:block"></div>
            <div
                className="w-20 h-40 absolute dark:bg-gray-300 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block">
            </div>
        </div>

    </div>
  );
}
