import { createSlice } from '@reduxjs/toolkit'
import { getAllCategory } from './categotyThunk'

const initialState = {
  category: []
}

export const categorySlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllCategory.fulfilled, (state, action) => {
      // Add user to the state array
      state.category = action?.payload?.data
    })
    builder.addCase(getAllCategory.rejected, (state, action) => {
        // Add user to the state array

      })
  },

})

export default categorySlice.reducer