import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api/api";
import { apiEndPoints } from "../../api";

export const getAuthorNews = createAsyncThunk('getAuthorNews', async ({page,limit,name}) => {

  try {
    const data = await api.get(apiEndPoints.getMyNews( page,limit,name));
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Author News Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
