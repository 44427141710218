import { createSlice } from '@reduxjs/toolkit'
import { leftSideByCategory, leftSideSecound, rightSideData, rightSideFour, rightSideSecound, rightSideThird } from './leftSideDataThunk'

const initialState = {
  leftSide : []
}

export const leftSideDataSlice = createSlice({
  name: 'leftSideDataSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(leftSideByCategory.fulfilled, (state, action) => {
      // Add user to the state array
      state.leftSide = action?.payload?.data
    })
    builder.addCase(leftSideByCategory.rejected, (state, action) => {
        // Add user to the state array

    })
    builder.addCase(leftSideSecound.fulfilled, (state, action) => {
      // Add user to the state array
      state.leftSideSecound = action?.payload?.data
    })
    builder.addCase(leftSideSecound.rejected, (state, action) => {
        // Add user to the state array
    })
    builder.addCase(rightSideData.fulfilled, (state, action) => {
      // Add user to the state array
      state.rightSide = action?.payload?.data
    })
    builder.addCase(rightSideData.rejected, (state, action) => {
        // Add user to the state array
    })
    builder.addCase(rightSideSecound.fulfilled, (state, action) => {
      // Add user to the state array
      state.rightSideSecound = action?.payload?.data
    })
    builder.addCase(rightSideSecound.rejected, (state, action) => {
        // Add user to the state array
    })
    builder.addCase(rightSideThird.fulfilled, (state, action) => {
      // Add user to the state array
      state.rightSideThird = action?.payload?.data
    })
    builder.addCase(rightSideFour.fulfilled, (state, action) => {
      // Add user to the state array
      state.rightSideFour = state.rightSideFour?[...state.rightSideFour,...action?.payload?.data?.data]:action?.payload?.data?.data
    })
    builder.addCase(rightSideThird.rejected, (state, action) => {
        // Add user to the state array
    })
  },

})

export default leftSideDataSlice.reducer