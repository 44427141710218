import { createSlice } from '@reduxjs/toolkit'
import { getAllCategory, getAuthorNews } from './categotyThunk'

const initialState = {
  authorNews: []
}

export const authorNewsSlice = createSlice({
  name: 'authorNews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAuthorNews.fulfilled, (state, action) => {
      // Add user to the state array
      state.authorNews = action?.payload?.data
    })
    builder.addCase(getAuthorNews.rejected, (state, action) => {
        // Add user to the state array

      })
  },

})

export default authorNewsSlice.reducer