import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api/api";
import { apiEndPoints } from "../../api";

export const newsByCategory = createAsyncThunk('newsByCategory',async (payload) => {
  try {
    const data = await api.get(payload?.latest?apiEndPoints.getLatestNews(payload?.page,payload?.limit):payload?.sub ? apiEndPoints.getNewsBySubCategory(payload?.page,payload?.limit,payload?.type) :apiEndPoints.getNewsByCategory(payload?.page,payload?.limit,payload?.type))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get Category Fail");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})

export const getNewsById = createAsyncThunk('getNewsById',async (id) => {
  try {
    const data = await api.get(apiEndPoints.getNewsById(id))
    if(!data.status){
      toast.error(data?.response?.data?.massage?data?.response?.data?.massage:"Get News Failed");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})

export const newsBySearch = createAsyncThunk('newsBySearch',async (payload) => {
  try {
    const data = await api.get(apiEndPoints.getNewsBySearch(payload?.page,payload?.limit,payload?.name))
    if(!data.status && payload){
      toast.error("News not Found");
    }else if((data?.data[0]?.data.length === 0)&&payload){
      toast.warning("News not Found");
    }
    return data;
  } catch (error) {
    // return rejectWithValue(error.message);
  }
})
