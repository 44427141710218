import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/userSlice'
import categoryReducer from './category/categorySlice'
import usersReducer from './admins/adminSlice'
import newsByCategoryReduser from './newsByCategory/newsByCategorySlice'
import leftSideDataReduser from './leftSideData/leftSideDataSlice'
import  authorNewsSlice  from './Author/categorySlice'
export const store = configureStore({
  reducer: {
    user: userReducer,
    category : categoryReducer,
    newsByCategory : newsByCategoryReduser,
    leftSideData : leftSideDataReduser,
    newsByAuthor : authorNewsSlice,
    users: usersReducer
  },
})