import { createSlice } from '@reduxjs/toolkit'
import {getNewsById, newsByCategory, newsBySearch} from './newsByCategoryThunk'

const initialState = {
  category: []
}

export const newsByCategorySlice = createSlice({
  name: 'newsByCategorySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(newsByCategory.fulfilled, (state, action) => {
      // Add user to the state array
      state.centerData = action?.payload?.data?.length === 1 ? action?.payload?.data[0] : action?.payload?.data
    })
    builder.addCase(newsByCategory.rejected, (state, action) => {
        // Add user to the state array

    })
    builder.addCase(getNewsById.fulfilled, (state, action) => {
      // Add user to the state array
      state.centerMainNews = action?.payload?.data
    })
    builder.addCase(getNewsById.rejected, (state, action) => {
        // Add user to the state array

    })
    builder.addCase(newsBySearch.fulfilled, (state, action) => {
        // Add user to the state array
        state.newsBySearch = action?.payload?.data
    })
  },

})

export default newsByCategorySlice.reducer