import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {Routes, Route} from "react-router-dom";
import MainAdminLogin from "./Pages/MainAdminLogin/index";
import AdminPanel from "./Pages/AdminPanel";
import HomePage from "./Pages/HomePage";
import AdminCategory from "./Pages/AdminCategory";
import AdminUsers from "./Pages/AdminUsers";
import AdminNews from "./Pages/AdminNews";
import CategoryPage from "./Pages/CategoryPage";
import NewsPage from "./Pages/NewsPage";
import AdminMyNews from "./Pages/AdminMyNews";
import AuthorPage from "./Pages/AuthorPage";
import AboutUs from "./Pages/AboutUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndCondition";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" exact element={<HomePage/>}/>
                <Route path="/login" exact element={<MainAdminLogin/>}/>
                <Route path="/admin-panel" exact element={<AdminPanel/>}/>
                <Route path="/admin-category" exact element={<AdminCategory/>}/>
                <Route path="/admin-mynews" element={<AdminMyNews/>}/>
                <Route path="/admin-user" exact element={<AdminUsers/>}/>
                <Route path="/admin-news" exact element={<AdminNews/>}/>
                <Route path="/:category/:id" exact element={<NewsPage/>}/>
                <Route path="/author/:name" exact element={<AuthorPage/>}/>
                <Route path="/:category" element={<CategoryPage/>}/>
                <Route path="/:category/:subCat/sub" element={<CategoryPage/>}/>
                <Route path="/about-us" element={<AboutUs/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/terms" element={<TermsAndConditions/>}/>
            </Routes>
            <ToastContainer/>
        </div>
    );
}

export default App;
