import {Button, Popconfirm, Select, Table} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminNav from "../../Components/AdminCategory";
import {
  deleteCategory,
  getAllCategory,
  postCategory,
} from "../../Redux/category/categotyThunk";
import "./index.css";
import { useNavigate } from "react-router-dom";
import {Input} from "antd/lib";
import {toast} from "react-toastify";
import {api, apiEndPoints} from "../../api";

export default function AdminCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addCategory, setAddCategory] = useState(false);
  const [Category, setCategory] = useState();
  const [addSubCategory, setAddSubCategory] = useState(false);
  const [removeSubCategory, setRemoveSubCategory] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([{ value: 'jack', label: 'Jack' },]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([{ value: 'jack', label: 'Jack' },]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategory,setSubCategory] = useState('')
  const [Error,setError] = useState('')
  const [Categories, setCategories] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const category = useSelector((state) => state?.category?.category);
  const role = localStorage.getItem('role');
  useEffect(()=>{
    if(!role){
      navigate("/")
    }else{
      if(role==="admin"){
        dispatch(getAllCategory());
      }else{
        navigate("/admin-panel")
      }
    }
  },[])

  useEffect(()=>{
    if(category){
      const data = Array.isArray(category)&&category?.map((item, index) => {
        return { key: index + 1, ...item };
      });
      setCategories(data)
      const options = data?.length && data.map((item)=>{
        return {value: item?.name, label: item?.name}
      })
      setCategoryOptions(options);
    }
  },[category])
  const columns = [
    {
      title: "No",
      dataIndex: "key",
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 3,
      },
    },
    {
      title: "Categories Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      render: function (text, record, index) {
        return (
          <Popconfirm
          title="Delete the category"
          description="Are you sure to delete this Category?"
          onConfirm={() => handleDelete(text?._id)}
          okText="Yes"
          cancelText="No"
        >
          <div class="text-center cursor-pointer font-medium text-red-500">
            Delete
          </div>
          </Popconfirm>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleAddCategory = async () => {
    Category&&await dispatch(postCategory({ name: Category }));
    Category&&await dispatch(getAllCategory());
    await setAddCategory(false);
    await setCategory();
  };

  const handleDelete = async (id) => {
    await dispatch(deleteCategory({ id: id }));
    await dispatch(getAllCategory());
  };

  const handleChangeCat = (value) => {
    const selectedOption = category?.filter((i) => i?.name === value )
    setSelectedOption(selectedOption[0]);
  }

  const handlechangeData = (event) => {
    if(event.target.value){
      setSubCategory(event.target.value);
      if(Error){
        setError('');
      }
    }else {
      setError('error');
    }
  }

  const onSubCategorySubmit = async () => {
    if(subCategory){
      if(selectedOption?.name){
        const categoys = {
          name : selectedOption?.name,
          subCat : [...selectedOption.subCat,subCategory]
        }
        const data = await api.post(apiEndPoints.editCategory(selectedOption?._id),categoys)
        if(data?.data){
          toast.success(data?.data?.massage)
          if(data?.data?.massage === "sub category created successfully"){
            setAddSubCategory(false);
            dispatch(getAllCategory());
          }
        }
      }else {
        toast.error('Please select Category first')
      }
    }else {
      setError('error');
    }
  }

  const handleChangeCatForRemove = (value) => {
    const selectedOption = category?.filter((i) => i?.name === value )
    setSelectedOption(selectedOption[0]);
    setSubCategoryOptions(selectedOption[0]?.subCat?.map((item) => {
      return {value: item, label: item}
    }))
  }

  const onDeleteSubCategorySubmit = async () => {
    if(selectedOption?.name && selectedSubCategory){
      const categoys = {
        name : selectedOption?.name,
        subCat : selectedOption.subCat?.filter(i => i !== selectedSubCategory)
      }
      const data = await api.post(apiEndPoints.editCategory(selectedOption?._id),categoys)
      if(data?.data){
        if(data?.data?.massage === "sub category created successfully"){
          toast.error("sub category removed successfully")
          setRemoveSubCategory(false);
          dispatch(getAllCategory());
        }else {
          toast.error(data?.data?.massage)
        }
      }
    }else {
      toast.error('Please select Category first')
    }
  }

  return (
    <div class="w-full bg-gray-100" style={{minHeight:"100vh"}}>
      <AdminNav />
      <div>
        {addCategory ? (
          <div className="p-8 flex items-center justify-center overflow-hidden dark:bg-gray-900 ">
            <input
              class="rounded-l-lg p-3 border-t mr-0 border-b border-l text-gray-800 border-[#1f2937] bg-white w-80"
              value={Category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            />
            <button
              className="px-8 add__category rounded-r-lg text-white bg-[#1f2937] font-bold p-3 uppercase border-yellow-500 border-t border-b border-r"
              onClick={handleAddCategory}
            >
              Add Category
            </button>
          </div>
        ) : addSubCategory ? <div className='p-8 flex flex-wrap items-center justify-center overflow-hidden dark:bg-gray-900'>
            <Select
                defaultValue={categoryOptions[0]?.label}
                style={{ width: 200 }}
                onChange={handleChangeCat}
                options={categoryOptions}
                size="large"
                className='mb-2'
            />
            <Input placeholder="Sub Category" className='mb-2 mx-3' onChange={handlechangeData} size="large" style={{ width: 200 }} status={Error ||''} />;
              <Button type="primary" style={{backgroundColor:"#1f2937",color:"#fff"}} onClick={onSubCategorySubmit} size={"large"}>
                Add Sub Category
              </Button>
        </div> : removeSubCategory ? <div className='p-8 flex flex-wrap items-center justify-center overflow-hidden dark:bg-gray-900'>
              <Select
                  defaultValue={categoryOptions[0]?.label}
                  style={{ width: 200 }}
                  onChange={handleChangeCatForRemove}
                  options={categoryOptions}
                  size="large"
                  className='mb-2'
              />
              <Select
                  style={{ width: 200 }}
                  onChange={(value) => setSelectedSubCategory(value)}
                  options={subCategoryOptions}
                  size="large"
                  className='mx-3 mb-2'
              />
              <Button type="primary" style={{backgroundColor:"#1f2937",color:"#fff"}} onClick={onDeleteSubCategorySubmit} size={"large"}>
                Remove Sub Category
              </Button>
            </div> :
        <div className="overflow-hidden relative dark:bg-gray-900">
        <Button type="primary" className="m-4" style={{backgroundColor:"#1f2937",color:"#fff"}} onClick={()=>setAddCategory(true)} size={"large"}>
          Add Category
        </Button>
          <Button type="primary" className="m-4" style={{backgroundColor:"#1f2937",color:"#fff"}} onClick={()=>setAddSubCategory(true)} size={"large"}>
          Add Sub Category
        </Button>
          <Button type="primary" className="m-4" style={{backgroundColor:"#1f2937",color:"#fff"}} onClick={()=> setRemoveSubCategory(true)} size={"large"}>
          Delete Sub Category
        </Button>
        </div>}
        <div>
          <section class="antialiased overflow-hidden relative dark:bg-gray-900 text-gray-600 px-4">
            <div
                className="absolute w-60 h-60 rounded-xl dark:bg-gray-300 -top-[-50px] -left-16 z-0 transform rotate-45 hidden md:block">
            </div>
            <div
                className="absolute w-48 h-48 rounded-xl dark:bg-gray-300 -bottom-6 -right-10 transform rotate-12 hidden md:block">
            </div>
            <div class="flex flex-col justify-center">
              <div class="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-sm border border-gray-200 rounded-xl">
                <header class="px-5 py-4 border-b border-gray-100">
                  <h2 class="font-semibold text-gray-800">Categories</h2>
                </header>
                <div class="p-3">
                  <div class="overflow-x-auto">
                    <Table columns={columns} dataSource={Categories} />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-40 h-40 absolute dark:bg-gray-300 rounded-full top-0 right-12 hidden md:block"></div>
            <div
                className="w-20 h-40 absolute dark:bg-gray-300 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block">
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
