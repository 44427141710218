import { createSlice } from '@reduxjs/toolkit'
import { getAllAdmin } from './adminThunk'

const initialState = {
  users: []
}

export const adminSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllAdmin.fulfilled, (state, action) => {
      // Add user to the state array
      state.users = action?.payload?.data
    })
    builder.addCase(getAllAdmin.rejected, (state, action) => {
        // Add user to the state array

      })
  },

})

export default adminSlice.reducer