import { useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import AdminNav from "../../Components/AdminCategory";

export default function AdminPanel() {
  const navigate = useNavigate();
  
  return (
    <div class="w-full">
      <AdminNav />
      <div class="flex bg-white" style={{ height: "93vh" }}>
        
        <div class="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
          <div>
            <h2 class="text-3xl font-semibold text-gray-800 md:text-4xl">
              Evil report news faster than any{" "}
              <span class="text-indigo-600">applause</span>.
            </h2>
            <p class="mt-2 text-sm text-gray-500 md:text-base">
              News is a content platform that publishes one positive story every
              day of the year. We are dedicated to unearthing inspiring,
              uplifting, and authentic voices that reflect the good in humanity,
              as well as the beauty of destinations and our natural world.
            </p>
            <div class="flex justify-center lg:justify-start mt-6">
              <a
                class="px-4 py-3 cursor-pointer bg-gray-900 text-gray-200 text-xs font-semibold rounded hover:bg-gray-800"
                href="#"
                onClick={() => {
                  navigate("/admin-news");
                }}
              >
                ADD NEWS
              </a>
              <a
                class="mx-4 px-4 py-3 cursor-pointer bg-gray-300 text-gray-900 text-xs font-semibold rounded hover:bg-gray-400"
                onClick={() => {
                  navigate("/");
                }}
              >
                NEWS PAGE
              </a>
            </div>
          </div>
        </div>
        <div
          class="hidden lg:block lg:w-1/2"
          style={{ clipPath: "polygon(10% 0, 100% 0%, 100% 100%, 0 100%)" }}
        >
          <div
            class="h-full object-cover"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80)",
            }}
          >
            <div class="h-full bg-black opacity-25"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
